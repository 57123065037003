import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './langs/I18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toasted from 'vue-toasted';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAnalytics from 'vue-analytics';
import {ResponseCodeEnum} from '@/models/ResponseCodeEnum';

import {loginService} from '@/services/LoginService';
import {toastService} from '@/services/ToastService';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBill, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import Paginate from 'vuejs-paginate'

library.add(faMoneyBill, faUser, faTimes);
Vue.component('paginate', Paginate)
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueFormWizard);
Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

axios.interceptors.request.use((config) => {
  // Do something before request is sent
  return loginService.addToken(config);
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});
axios.interceptors.response.use((res) => {
  if (res.data.code
    && res.data.code !== ResponseCodeEnum.CODE_SUCCESS
    && res.data.code !== ResponseCodeEnum.CODE_BUSINESS) {
    // Show error
    toastService.error(res.data.code);
  } else if (res.data.code && res.data.code === ResponseCodeEnum.CODE_BUSINESS) {
    // Show business error
    toastService.error(ResponseCodeEnum.CODE_BUSINESS, res.data.data);
  } else if (res.data.code && res.data.code === ResponseCodeEnum.CODE_EXPIRED) {
    // Logout
    toastService.error(ResponseCodeEnum.CODE_EXPIRED, res.data.data);
    loginService.logout();
    router.push({ name: 'login' });
  } else if (res.data.code && res.data.code === ResponseCodeEnum.CODE_INTERNAL_ERROR) {
    // Show internal error
    toastService.error(ResponseCodeEnum.CODE_INTERNAL_ERROR, res.data.data);
  }
  return res;
}, (error) => {
  // Do something with response error
  if (error.response && error.response.status) {
    if (error.response.status !== 400 && error.response.status !== 401) {
      toastService.errorMessage(error.response.data.error);
    } else if (error.response.status === 401) {
      // Logout
      // toastService.error(ResponseCodeEnum.CODE_EXPIRED);
      loginService.logout();
      router.push({ name: 'login' });
    }
  } else {
    toastService.errorMessage(error.message);
  }
  return Promise.reject(error);
});

// set authorization when the web is run (global)
axios.prototype.loadHeaderInfo = function() {
  // load user's info
  const jwttoken = localStorage.getItem('jwttoken');
  if (jwttoken) {
    axios.defaults.headers.common.Authorization = jwttoken;
  }

  // load language
  const lang = localStorage.getItem('Language');
  axios.defaults.headers.common.Language = lang;
};
axios.prototype.loadHeaderInfo();

// tslint:disable-next-line: only-arrow-functions
axios.prototype.hasUserInfo = function() {
  return (axios.defaults.headers.common.Authorization != null) ? true : false;
};


// set user infomation
axios.prototype.setUserInfo = function(token: string) {
  const jwttoken = 'jwttoken:' + token;
  localStorage.setItem('jwttoken', jwttoken);
  axios.defaults.headers.common.Authorization = jwttoken;
};
Vue.use(VueAxios, axios);

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 3000,
  singleton: true,
});

// Vue.use(VueAnalytics, {
//   id: 'UA-166122925-2',
//   router,
// });

const mainApp = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default mainApp;
