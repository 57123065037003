

































































































import { Vue, Component } from 'vue-property-decorator';
import { userService } from '@/services';
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {}
})
export default class TransactionMenuPage extends Vue {
  private balance = 0;
  private balanceString = '0';

  private created() {
    userService
      .getUserProfile()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.balance = res.data.data.balance;
          this.balanceString = this.formatPrice(res.data.data.balance);
        }
      })
  }

  private goToPage(path: string) {
    if(path === "/transaction-history") {
      this.$router.push({ path: path });
    } else {
      userService
      .getUserProfile()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          if(res.data.data.lockAccount === 1) {
            alert(this.$t('system_warning_message.user.user_lock').toString())
          } else {
            this.$router.push({ path: path });
          }
        }
      })
    }
  }

  private formatPrice(value: any) {
    const val = (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
