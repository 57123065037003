








































































import { Vue, Component } from 'vue-property-decorator';
import moment, { tz } from "moment-timezone";

@Component({
  components: {}
})
export default class DepositWithWalletPage extends Vue {
}
