import Vue from 'vue';
import axios from 'axios'

const GET_MODEL_BY_PLATFORM = process.env.VUE_APP_API_URL + "/webapi/model/getModelByPlatform";
const GET_MODEL = process.env.VUE_APP_API_URL + "/webapi/model";
const CHECK_WARNING_USER_LOGIN = process.env.VUE_APP_API_URL + "/webapi/model/checkWarningUserLogin";

class ModelService extends Vue {

  public showWarningPopup() {
    return this.axios.get(CHECK_WARNING_USER_LOGIN);
  }

  public getModelByPlatform(platform: number) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })
    return instance.post(GET_MODEL_BY_PLATFORM, { platform });
  }

  public getByModelId(modelId: number) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    })
    return instance.get(GET_MODEL + "/" + modelId);
  }
}

export const modelService = new ModelService();

