import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

const URL_ENV = process.env.VUE_APP_API_URL + '/webapi/transaction-btse';

class TransactionBTSEService extends Vue {

  public getRate(coin: any, protocol: any): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URL_ENV + '/get-rate?coin=' + coin + '&protocol=' + protocol);
  }

  public created(data: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV + '/create', data);
  }

  public updatePending(transactionId: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV + '/update-pending?transactionId=' + transactionId);
  }
}
export const transactionBTSEService = new TransactionBTSEService();