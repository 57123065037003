






























































































































































import { Component, Vue } from "vue-property-decorator";
import ResponseCommon from "@/commons/ResponseCommon";
import Constants from "@/commons/Constants";
import ModelItem from "@/components/ModelView.vue";
import { modelService } from "@/services";
import { Model } from "@/models";

@Component({
  components: {
    ModelItem,
  },
})
export default class Content extends Vue {
  private models1: Model[] = [];
  private models2: Model[] = [];
  private models3: Model[] = [];

  private modelssp1: Model[] = [];
  private modelssp2: Model[] = [];
  private modelssp3: Model[] = [];
  private modelssp4: Model[] = [];
  private modelssp5: Model[] = [];
  private modelssp6: Model[] = [];
  private switchStatus = true;
  private offset = 0;
  private LIMIT_PACHI = 4;
  private LIMIT_SLOT = 3;
  private LIMIT_SP = 2;
  private isRegisterModal = false;
  private linkBackGame = "";

  // private mounted() {
  //   modelService.showWarningPopup().then((response) => {
  //     if (response.data.code === ResponseCommon.CODE_SUCCESS) {
  //       this.isRegisterModal = true;
  //       this.linkBackGame = response.data.data;
  //     }
  //   });
  // }

  private created() {
    // if (this.$store.state.shopGame === Constants.SLOT.toString()) {
    //   this.getModelPachi(Constants.SLOT);
    // } else {
      this.getModelPachi(Constants.PACHINKO); // Default is pachinko
    // }
  }

  // private hideModal() {
  //   this.isRegisterModal = false;
  //   window.location.href = this.linkBackGame;
  // }

  private getModelPachi(value: number) {
    modelService.getModelByPlatform(value).then((response) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        if (value === Constants.PACHINKO) {
          // Case PACHINKO
          this.switchStatus = true;
          // this.$store.dispatch("setShopGame", Constants.PACHINKO.toString());

          this.models1 = response.data.data.slice(0, this.LIMIT_PACHI);
          this.models2 = response.data.data.slice(
            this.LIMIT_PACHI,
            this.LIMIT_PACHI * 2
          );
          this.models3 = response.data.data.slice(
            this.LIMIT_PACHI * 2,
            this.LIMIT_PACHI * 3
          );

          this.modelssp1 = response.data.data.slice(0, this.LIMIT_SP);
          this.modelssp2 = response.data.data.slice(
            this.LIMIT_SP,
            this.LIMIT_SP * 2
          );
          this.modelssp3 = response.data.data.slice(
            this.LIMIT_SP * 2,
            this.LIMIT_SP * 3
          );
          this.modelssp4 = response.data.data.slice(
            this.LIMIT_SP * 3,
            this.LIMIT_SP * 4
          );
          this.modelssp5 = response.data.data.slice(
            this.LIMIT_SP * 4,
            this.LIMIT_SP * 5
          );
          this.modelssp6 = response.data.data.slice(
            this.LIMIT_SP * 5,
            this.LIMIT_SP * 6
          );
        } 
        // else {
        //   // Case SLOT
        //   this.switchStatus = false;
        //   this.$store.dispatch("setShopGame", Constants.SLOT.toString());

        //   this.models1 = response.data.data.slice(0, this.LIMIT_SLOT);
        //   this.models2 = response.data.data.slice(
        //     this.LIMIT_SLOT,
        //     this.LIMIT_SLOT * 2
        //   );

        //   this.modelssp1 = response.data.data.slice(0, this.LIMIT_SP);
        //   this.modelssp2 = response.data.data.slice(
        //     this.LIMIT_SP,
        //     this.LIMIT_SP * 2
        //   );
        //   this.modelssp3 = response.data.data.slice(
        //     this.LIMIT_SP * 2,
        //     this.LIMIT_SP * 3
        //   );
        // }
      }
    });
  }
}
