





































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TermsOfUse extends Vue {

  private async created() {
          // await this.getMachinesByModel(this.modelId);
      // await this.getModel();
      // await this.getImgHeader();
      // Reload state machine every 5 minutes in 1 time
      // this.intervalid = setInterval(() => {
      // this.getMachinesByModel(this.modelId);
      // }, 300000);
  }
  mounted() {
  const plugin = document.createElement("script");
  plugin.setAttribute(
    "src",
    "https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"
  );
  plugin.async = true;
  document.head.appendChild(plugin);
  }
}
 // Scroll
 
