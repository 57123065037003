































































































































import { Vue, Component } from 'vue-property-decorator';
import { transactionHistoryService, transactionPaystage } from '@/services';
import moment, { tz } from "moment-timezone";
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {}
})
export default class TransactionHistoryPage extends Vue {
  private pageCount = 1;
  private transactionHistoryList: any = [];
  private pageable: any = {
    currentPage: 1,
    perPage: 10,
    filter: '',
    sortBy: '',
    sortDesc: true,
  }
  private status: any;
  private transactionNo: any;
  private referenceNo: any;
  private showTable = false;
  private msgProcesss = false;
  private msgFailed = false;
  private moment = moment;

  private showDtail(id: string) {
    const trDetail1 = document.getElementById('trDetail1-' + id)
    trDetail1!.style.display = trDetail1!.style.display === 'none' ? '' : 'none';
    const trDetail2 = document.getElementById('trDetail2-' + id)
    trDetail2!.style.display = trDetail2!.style.display === 'none' ? '' : 'none';
    const tdIcon = document.getElementById('tdIcon-' + id)
    tdIcon!.innerHTML = trDetail1!.style.display && trDetail2!.style.display ? '▼' : '▲'
  }

  private async created() {
    this.status = this.$route.query.status;
    this.transactionNo = this.$route.query.transaction_no;
    this.referenceNo = this.$route.query.reference_no;
    console.log(this.status + " - " + this.transactionNo + " - " + this.referenceNo)
    if(this.status && this.referenceNo && this.transactionNo) {
      this.getTransactionPaystageDetail();
    } else {
      this.showTable = true;
      this.reloadTable();
    }
  }

  private getTransactionPaystageDetail() {
    this.showTable = false;
    transactionPaystage
      .getTransactionPaystageByUserIdAndReferenceNo(this.referenceNo)
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          if(res.data.data && res.data.data.status === 'pending') {
            this.msgFailed = false;
            this.msgProcesss = true;
            setTimeout(() => {
              this.getTransactionPaystageDetail();
            }, 2000)
          } else if(res.data.data && res.data.data.status === 'failed') {
            this.msgProcesss = false;
            this.msgFailed = true;
          } else {
            this.msgFailed = false;
            this.msgProcesss = false;
            this.showTable = true;
            this.reloadTable();
          }
        }
      })
  }

  private async reloadTable() {
    await transactionHistoryService.getAllByUserId(
      'currentPage=' + this.pageable.currentPage + '&' + 'perPage=' + this.pageable.perPage + '&' + 'filter=' + this.pageable.filter + '&' + 'sortBy=' + this.pageable.sortBy + '&' + 'sortDesc=' + this.pageable.sortDesc
    ).then((res: any) => {
      this.transactionHistoryList = res.data.items;
      this.pageCount = res.data.totalRows / this.pageable.perPage;
    })
  }

  private convertTimeZone(datetime: any) {
    if (datetime) {
      const d = new Date(datetime);
      return moment(d).format("YYYY-MM-DD HH:mm:ss (Z)");
    }
  }

  private changePerPage() {
    this.pageable.currentPage = 1;
    this.reloadTable();
  }
}
