export class ManageKyc {
    public id?: number;
    public userId?: number;
    public firstName?: string;
    public lastName?: string;
    public documentType?: string;
    public image1?: string;
    public image2?: string;
    public image3?: string;
    public image4?: string;
    public image5?: string;
    public document?: number;
    public kyc?: number;
    public documentSubmissionDate?: Date;
    public status?: number;

    public fileImage1 = null;
    public fileImage2 = null;
    public fileImage3 = null;
    public fileImage4 = null;
    public fileImage5 = null;
  
    constructor(init?: Partial<ManageKyc>) {
      Object.assign(this, init)
    }
  }