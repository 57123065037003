

























































































































































































import { Component, Vue } from "vue-property-decorator";
import ResponseCommon from "@/commons/ResponseCommon";
import Constants from "@/commons/Constants";
import ModelItem from "@/components/ModelView.vue";
import { modelService, commonService } from "@/services";
import { Model } from "@/models";
import moment from 'moment';

@Component({
  components: {
    ModelItem,
  },
})
export default class SelectFloor extends Vue {
  private models1: Model[] = [];
  private models2: Model[] = [];
  private models3: Model[] = [];
  private models4: Model[] = [];
  private models5: Model[] = [];
  private models6: Model[] = [];

  private modelssp1: Model[] = [];
  private modelssp2: Model[] = [];
  private modelssp3: Model[] = [];
  private modelssp4: Model[] = [];
  private modelssp5: Model[] = [];
  private modelssp6: Model[] = [];
  private switchStatus = true;
  private offset = 0;
  private LIMIT_PACHI = 4;
  private LIMIT_SLOT = 4;
  private LIMIT_SP = 2;
  private isRegisterModal = false;
  private linkBackGame = "";

  // private mounted() {
  //   modelService.showWarningPopup().then((response) => {
  //     if (response.data.code === ResponseCommon.CODE_SUCCESS) {
  //       this.isRegisterModal = true;
  //       this.linkBackGame = response.data.data;
  //     }
  //   });
  // }

  private timer!: number;

  async mounted() {
    this.checkShifts();
    this.timer = setInterval(() => {
      this.checkShifts();
    }, 10000);
  }

  private async checkShifts() {
    let timeJP = moment.utc().tz('Asia/Tokyo').format('HH:mm');
    await commonService.getCurrentTimeOfTheServer()
      .then((res: any) => {
        if(res.data.code === ResponseCommon.CODE_SUCCESS){
          timeJP = moment.utc(res.data.data).tz('Asia/Tokyo').format('HH:mm');
        }
      })
    const condition1 = timeJP >= moment('18:00', 'HH:mm').format('HH:mm') && timeJP <= moment('19:59', 'HH:mm').format('HH:mm');
    const condition2 = timeJP >= moment('06:00', 'HH:mm').format('HH:mm') && timeJP <= moment('07:59', 'HH:mm').format('HH:mm');
    if(condition1 || condition2) {
      this.$router.replace({path: '/shift-has-ended'})
    }
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  private created() {
    // if (this.$store.state.shopGame === Constants.SLOT.toString()) {
    //   this.getModelPachi(Constants.SLOT);
    // } else {
      this.getModelPachi(Constants.PACHINKO); // Default is pachinko
      this.getModelPachiSlot(Constants.SLOT);
    // }
  }

  // private hideModal() {
  //   this.isRegisterModal = false;
  //   window.location.href = this.linkBackGame;
  // }

  private getModelPachi(value: number) {
    modelService.getModelByPlatform(value).then((response) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        
        if (value === Constants.PACHINKO) {
          // Case PACHINKO
          this.switchStatus = true;
          // this.$store.dispatch("setShopGame", Constants.PACHINKO.toString());
          this.models1 = response.data.data.slice(0, this.LIMIT_PACHI);
          // this.models2 = response.data.data.slice(this.LIMIT_PACHI , this.LIMIT_PACHI + 4);
          this.models3 = response.data.data.slice(this.LIMIT_PACHI , this.LIMIT_PACHI + 4);

          // this.models3 = response.data.data.slice(
          //   this.LIMIT_PACHI * 2,
          //   this.LIMIT_PACHI * 3
          // );

          // this.modelssp1 = response.data.data.slice(0, this.LIMIT_SP);
          // this.modelssp2 = response.data.data.slice(
          //   this.LIMIT_SP,
          //   this.LIMIT_SP * 2
          // );
          // this.modelssp3 = response.data.data.slice(
          //   this.LIMIT_SP * 2,
          //   this.LIMIT_SP * 3
          // );
          // this.modelssp4 = response.data.data.slice(
          //   this.LIMIT_SP * 3,
          //   this.LIMIT_SP * 4
          // );
          // this.modelssp5 = response.data.data.slice(
          //   this.LIMIT_SP * 4,
          //   this.LIMIT_SP * 5
          // );
          // this.modelssp6 = response.data.data.slice(
          //   this.LIMIT_SP * 5,
          //   this.LIMIT_SP * 6
          // );
        } 
        // else {
        //   // Case SLOT
        //   this.switchStatus = false;
        //   this.$store.dispatch("setShopGame", Constants.SLOT.toString());

        //   this.models1 = response.data.data.slice(0, this.LIMIT_SLOT);
        //   this.models2 = response.data.data.slice(
        //     this.LIMIT_SLOT,
        //     this.LIMIT_SLOT * 2
        //   );

        //   this.modelssp1 = response.data.data.slice(0, this.LIMIT_SP);
        //   this.modelssp2 = response.data.data.slice(
        //     this.LIMIT_SP,
        //     this.LIMIT_SP * 2
        //   );
        //   this.modelssp3 = response.data.data.slice(
        //     this.LIMIT_SP * 2,
        //     this.LIMIT_SP * 3
        //   );
        // }
      }
    });
  }

  private getModelPachiSlot(value: number) {
    modelService.getModelByPlatform(value).then((response) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        if (value === Constants.SLOT) {
          this.models4 = response.data.data.slice(0, this.LIMIT_SLOT);
          this.models5 = response.data.data.slice(this.LIMIT_SLOT , this.LIMIT_SLOT + 4);
          this.models6 = response.data.data.slice(this.LIMIT_SLOT + 4, this.LIMIT_SLOT + 8);
        }

          // this.models3 = response.data.data.slice(
          // //   this.LIMIT_PACHI * 2,
          // //   this.LIMIT_PACHI * 3
          //     this.LIMIT_SLOT,
          //     this.LIMIT_SLOT * 2
          // );
          // this.switchStatus = false;

          // this.models1 = response.data.data.slice(0, this.LIMIT_SLOT);
          // this.models2 = response.data.data.slice(
          //   this.LIMIT_SLOT,
          //   this.LIMIT_SLOT * 2
          // );

          this.modelssp1 = response.data.data.slice(0, this.LIMIT_SP);
          this.modelssp2 = response.data.data.slice(
            this.LIMIT_SP,
            this.LIMIT_SP * 2
          );
          this.modelssp3 = response.data.data.slice(
            this.LIMIT_SP * 2,
            this.LIMIT_SP * 3
          );
      //   }
      }
    });
  }
}
