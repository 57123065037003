





import router from '@/router';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ProgressOauth extends Vue {
  private created() {
    const clientId = this.$route.query.client_id;
    const responseType = this.$route.query.response_type;
    const redirectUri = this.$route.query.redirect_uri;
    const scope = this.$route.query.scope;
    const state = this.$route.query.state;
    if (this.axios.defaults.headers.common.Authorization == null) {
      const path = 'login?';
      const queryParams = [ 'client_id=' + clientId,
        'redirect_uri=' + redirectUri,
        'scope=' + scope,
        'response_type=' + responseType
      ];
      const query = queryParams.join('&');
      const url = path + query;
      router.push(url);
    } else {
      const path = 'oauth/authorize?';
      const queryParams = [ 'client_id=' + clientId,
              'redirect_uri=' + redirectUri,
              'scope=' + scope,
              'response_type=' + responseType];
      const query = queryParams.join('&');
      const url = process.env.VUE_APP_API_URL + '/' + path + query;
      this.axios.get(url).then((response: any) => {
        window.location.href = response.request.responseURL;
      }).catch(error => {
        console.log(error);
      });
      // this.$router.push('/');
    }
  }
}
