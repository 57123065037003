import { DataTableRequest } from './../models/DataTableRequest';
import Vue from 'vue';

class PlayHistoryService extends Vue {

  public getAllByCurrentLoginId(dataTableRequest: DataTableRequest) {
    return this.axios.get(process.env.VUE_APP_API_URL + '/webapi/play-history'
      + '?currentPage=' + dataTableRequest.currentPage
      + '&perPage=' + dataTableRequest.perPage
      + '&filter=' + dataTableRequest.filter
      + '&sortBy=' + dataTableRequest.sortBy
      + '&sortDesc=' + dataTableRequest.sortDesc  
    );
  }
}
export const playHistoryService = new PlayHistoryService();
