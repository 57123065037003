











































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { userService, userCheatService, manageKycService } from '@/services';
import { User } from '@/models';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {}
})
export default class UserProfilePage extends Vue {
  private tab = 'TAB_LEFT';

  private user = new User();
  private loginID = '';
  private email = '';
  private balance = '';

  private regexNickNamePassword = /^[ぁ-んァ-ン一-龥a-zA-Z0-9]+$/;
  private emailUpdate ='';
  private emailUpdateConfirm ='';
  private passwordUpdate = '';
  private passwordUpdateConfirm = '';

  private alertMsg = {
    nickName : {
      status: '',
      msg: '',
    },
    email : {
      status: '',
      msg: '',
    },
    password : {
      status: '',
      msg: '',
    },
    mobileNumber : {
      status: '',
      msg: '',
    }
  }

  private changeTab(tab: string) {
    this.tab = tab;
  }

  private created() {
    this.getUser();
    // Reload info user in header every 10 seconds in 1 time
    // setInterval(() => {
    //   this.reloadBalance();
    // }, 10000);
  }

  private reloadBalance() {
    userService.getUserProfile().then((response) => {
      if (response && response.data && response.data.data) {
        this.balance = this.formatPrice(response.data.data.balance);
      }
    });
  }

  private getUser() {
    userService.getUserProfile().then((response) => {
      if (response && response.data && response.data.data) {
        this.user = response.data.data;

        this.balance = this.formatPrice(this.user.balance);
        this.loginID = this.user.userName;
        this.email = this.user.email;
      }
    });
  }

  private formatPrice(value: any) {
    const val = 'USD ' + (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private updateNickName() {
    this.alertMsg.nickName.status = '';
    this.alertMsg.nickName.msg = '';
    userService.updateNickName({
      nickName: this.user.nickName,
      email: "",
      emailConfirm: "",
      password: "",
      passwordConfirm: "",
      mobileNumber: ""
    }).then((res: any) => {
      if(res.data) {
        if(res.data.code === ResponseCommon.CODE_SUCCESS) {
          this.user.nickName = res.data.data;
          this.alertMsg.nickName.status = 'success';
          this.alertMsg.nickName.msg = 'view.userprofile.nickname_success';
        } else {
          this.alertMsg.nickName.status = 'error';
          this.alertMsg.nickName.msg = res.data.data;
        }
      }
    })
  }

  private updateEmail() {
    this.alertMsg.email.status = '';
    this.alertMsg.email.msg = '';
    userService.updateEmail({
      email: this.emailUpdate,
      emailConfirm: this.emailUpdateConfirm
    }).then((res: any) => {
      if(res.data) {
        if(res.data.code === ResponseCommon.CODE_SUCCESS) {
          this.email = res.data.data;
          this.alertMsg.email.status = 'success';
          this.alertMsg.email.msg = 'view.userprofile.email_success';
        } else {
          this.alertMsg.email.status = 'error';
          this.alertMsg.email.msg = res.data.data;
        }
      }
    })
  }

  private updatePassword() {
    this.alertMsg.email.status = '';
    this.alertMsg.email.msg = '';
    userService.updatePassword({
      password: this.passwordUpdate,
      passwordConfirm: this.passwordUpdateConfirm
    }).then((res: any) => {
      if(res.data) {
        if(res.data.code === ResponseCommon.CODE_SUCCESS) {
          this.alertMsg.password.status = 'success';
          this.alertMsg.password.msg = 'view.userprofile.password_success';
        } else {
          this.alertMsg.password.status = 'error';
          this.alertMsg.password.msg = res.data.data;
        }
      }
    })
  }

  private updateMobileNumber() {
    this.alertMsg.mobileNumber.status = '';
    this.alertMsg.mobileNumber.msg = '';
    userService.updateMobileNumber({
      mobileNumber: this.user.mobileNumber
    }).then((res: any) => {
      if(res.data) {
        if(res.data.code === ResponseCommon.CODE_SUCCESS) {
          this.user.mobileNumber = res.data.data;
          this.alertMsg.mobileNumber.status = 'success';
          this.alertMsg.mobileNumber.msg = 'view.userprofile.mobile_number_success';
        } else {
          this.alertMsg.mobileNumber.status = 'error';
          this.alertMsg.mobileNumber.msg = res.data.data;
        }
      }
    })
  }

  private registerKYC() {
    manageKycService.getKYCInformation().then((res: any) => {
      if(res.data && res.data.code === ResponseCommon.CODE_SUCCESS) {
        if(res.data.data.points) {
          if(res.data.data.information) {
            if(res.data.data.information.documentStatus === 'REQUEST_SENT') {
              this.$router.push({ path: '/manage-KYC' });
            } else if(res.data.data.information.kycStatus === 'DONE') {
              alert(this.$t('kyc_register.kyc_done').toString());
            } else {
              alert(this.$t('kyc_register.kyc_pending_2').toString());
            }
          } else {
            this.$router.push({ path: '/manage-KYC' });
          }
        } else {
          if(res.data.data.information) {
            if(res.data.data.information.kycStatus === 'DONE') {
              alert(this.$t('kyc_register.kyc_done').toString());
              return;
            }
            if(res.data.data.information.documentStatus === 'REQUEST_SENT') {
              this.$router.push({ path: '/manage-KYC' });
              return;
            }
            alert(this.$t('kyc_register.kyc_pending_2').toString());
            return;
          }
          this.$router.push({ path: '/manage-KYC' });
        }
      }
    })
  }
}
