


















































































































































import { Vue, Component } from 'vue-property-decorator';
import { transactionPaystage } from '@/services'
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {}
})
export default class ReceiveCreditCard extends Vue {
  private currencyType = 'USD';
  private amount = 0;
  private step = 1;
  private amountReceive = 0;
  private paymentFee = 0;
  private totalDeposit = 0;
  private fee = 0.05;
  private showCheckValid = false;
  private msgErrorCheckValid = '';
  private listErrorMsg: string[] = [];
  private errorCustomer = false;
  private errorUnknow = false;
  private sendMessage = '';
  
  private deposit() {
    this.showCheckValid = false;
    this.msgErrorCheckValid = '';
    if (this.amount < 30 || this.amount > 3000) {
      this.showCheckValid = true;
      if (this.amount < 1) {
        this.msgErrorCheckValid = 'deposit_with_credit_card.receive_credit_card.error_msg.amount_is_empty';
      } else {
        this.msgErrorCheckValid = 'deposit_with_credit_card.receive_credit_card.error_msg.amount_between';
      }
    } else {
      this.step = 2;
    }
  }

  private changeAmountBtn() {
    this.step = 1;
  }

  private changeAmount() {
    this.amountReceive = this.amount * this.calculateRateAmountToUsd(this.currencyType);
    this.paymentFee  = Number((this.amountReceive * this.fee).toFixed(2));
    this.totalDeposit = this.amountReceive + this.paymentFee;
  }

  private calculateRateAmountToUsd(currency: string) {
    let rate = 1;
    switch (currency) {
      case "JPY":
        rate = 145;
        break;
      default:
        rate = 1;
        break;
    }
    return rate;
  }

  private confirmDeposit() {
    (this.$refs.buttonConfirmDeposit as any).disabled = true;
    this.listErrorMsg = []
    transactionPaystage
      .createTransactionPaystage({
        amountDeposit: this.amount,
        totalDeposit: this.totalDeposit,
        totalFee: this.paymentFee,
        type: "DEPOSIT"
      })
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          window.location.href = res.data.data;
        } else if(res.data && res.data.code === 422 && res.data.data.errorBody) {
          this.step = -1;
          this.errorCustomer = true;
          const listError = JSON.parse(res.data.data.errorBody).error.errors
          for (const [key, value] of Object.entries(listError)) {
						for (const mess of (value as any)) {
							if (this.$store.state.storedLang !== 'en') {
								if (key === 'customer.address_line_1') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.address_line_1').toString());
								}
								else if (key === 'customer.address_line_2') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.address_line_2').toString());
								}
								else if (key === 'customer.city') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.city').toString());
								}
								else if (key === 'customer.country') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.country').toString());
								}
								else if (key === 'customer.email') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.email').toString());
								}
								else if (key === 'customer.first_name') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.first_name').toString());
								}
								else if (key === 'customer.last_name') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.last_name').toString());
								}
								else if (key === 'customer.mobile') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.mobile').toString());
								}
								else if (key === 'customer.state') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.state').toString());
								}
								else if (key === 'customer.zip') {
									this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.customer.zip').toString());
								} 
								else {
                  this.listErrorMsg.push(mess.replace('customer.',''));
								}
							} else {
                this.listErrorMsg.push(mess.replace('customer.',''));
							}
						}
					}
        } else if(res.data && res.data.code === 403) {
          this.step = -1;
          this.errorUnknow = true;
          this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.use_cheat').toString());
        } else if(res.data && res.data.code === 9999) {
          this.step = -1;
          this.errorUnknow = true;
          this.listErrorMsg.push(res.data.data);
          this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.unknow').toString());
        } else {
          this.step = -1;
          this.errorUnknow = true;
          this.listErrorMsg.push(res.data.data.errorBody ? JSON.parse(res.data.data.errorBody).error.message : res.data.data.errorMessage);
          this.listErrorMsg.push(this.$t('deposit_with_credit_card.receive_credit_card.error_msg.unknow').toString());
        }
      }).finally(() => (this.$refs.buttonConfirmDeposit as any).disabled = false)
  }

  private backToCustomerInfo() {
    this.$router.push({
      name: 'deposit-with-credit-card'
    });
  }

  private contactUs() {
    this.$router.push({
      name: 'contactUs',
      query: {
        message: this.listErrorMsg[0]
      }
    });
  }

  private cancelDeposit() {
    this.$router.push({
      name: 'transaction'
    });
  }
}
