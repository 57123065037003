
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Model } from '@/models';

@Component({
  components: {},
})
export default class ModelView extends Vue {
  @Prop() private model!: Model;
  @Prop() private index!: number;
  private MODEL_01 = 2001;
  private MODEL_02 = 2002;
  private MODEL_03 = 2003;
  private MODEL_04 = 2004;
  private MODEL_05 = 2005;
  private MODEL_06 = 2006;

  private isSlot = false;
  private imgPath = '';
  private imageTagRight = '';
  private imageTagLeft = '';
  private imageEquivalent = false;

  private getPurchasePrice() {
    switch(this.model.purchasePrice) {
      // case 0.01: return '1';
      // case 0.02: return '2';
      // case 0.04: return '4';
      // case 0.05: return '5';
      // case 0.1: return '10';
      // case 0.2: return '20';
      // case 0.08: return '8';
      // case 0.4: return '40';

      case 0.01: return '001';
      case 0.02: return '002';
      case 0.04: return '004';
      case 0.05: return '005';
      case 0.1: return '01';
      case 0.2: return '02';
      case 0.08: return '008';
      case 0.4: return '04';
    }
    
  }

  private created() {
    // Check SLOT img path
    if (this.model.id === this.MODEL_01 || this.model.id === this.MODEL_04) {
      this.isSlot = true;
      this.imgPath = 'assets/images/model/churasai_2.png';
    } else if (this.model.id === this.MODEL_02 || this.model.id === this.MODEL_05) {
      this.isSlot = true;
      this.imgPath = 'assets/images/model/churasai_1.png';
    } else if (this.model.id === this.MODEL_03 || this.model.id === this.MODEL_06) {
      this.isSlot = true;
      this.imgPath = 'assets/images/model/churasai_3.png';
    } else {
      this.isSlot = false;
    }

    if (this.model.purchasePrice && this.model.cashingPrice) {
      if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.009
      ) {
        this.imageTagLeft = 'assets/images/tag/1YenLeft.png';
        this.imageTagRight = 'assets/images/tag/1YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.018
      ) {
        this.imageTagLeft = 'assets/images/tag/2YenLeft.png';
        this.imageTagRight = 'assets/images/tag/2YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.036
      ) {
        this.imageTagLeft = 'assets/images/tag/4YenLeft.png';
        this.imageTagRight = 'assets/images/tag/4YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.01
      ) {
        this.imageTagLeft = 'assets/images/tag/1YenLeft.png';
        this.imageTagRight = 'assets/images/tag/1YenRight.png';
        this.imageEquivalent = true;
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.02
      ) {
        this.imageTagLeft = 'assets/images/tag/2YenLeft.png';
        this.imageTagRight = 'assets/images/tag/2YenRight.png';
        this.imageEquivalent = true;
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.04
      ) {
        this.imageTagLeft = 'assets/images/tag/4YenLeft.png';
        this.imageTagRight = 'assets/images/tag/4YenRight.png';
        this.imageEquivalent = true;
      } else if (
        this.model.purchasePrice === 0.05 &&
        this.model.cashingPrice === 0.045
      ) {
        this.imageTagLeft = 'assets/images/tag/5YenLeft.png';
        this.imageTagRight = 'assets/images/tag/5YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.1 &&
        this.model.cashingPrice === 0.09
      ) {
        this.imageTagLeft = 'assets/images/tag/10YenLeft.png';
        this.imageTagRight = 'assets/images/tag/10YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.2 &&
        this.model.cashingPrice === 0.18
      ) {
        this.imageTagLeft = 'assets/images/tag/20YenLeft.png';
        this.imageTagRight = 'assets/images/tag/20YenRight.png';
        this.imageEquivalent = false;
      } else if (
        this.model.purchasePrice === 0.05 &&
        this.model.cashingPrice === 0.05
      ) {
        this.imageTagLeft = 'assets/images/tag/5YenLeft.png';
        this.imageTagRight = 'assets/images/tag/5YenRight.png';
        this.imageEquivalent = true;
      } else if (
        this.model.purchasePrice === 0.1 &&
        this.model.cashingPrice === 0.1
      ) {
        this.imageTagLeft = 'assets/images/tag/10YenLeft.png';
        this.imageTagRight = 'assets/images/tag/10YenRight.png';
        this.imageEquivalent = true;
      } else if (
        this.model.purchasePrice === 0.2 &&
        this.model.cashingPrice === 0.2
      ) {
        this.imageTagLeft = 'assets/images/tag/20YenLeft.png';
        this.imageTagRight = 'assets/images/tag/20YenRight.png';
        this.imageEquivalent = true;
      }
    }
  }

  private goToMachine(model: Model) {
    this.$router.push({
      name: 'machine',
      params: { modelId: model.id + '' },
    });
  }
}
