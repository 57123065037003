










import { Component, Vue } from 'vue-property-decorator';
import Header from './views/layouts/Header.vue';
import Content from './views/layouts/Content.vue';
import Footer from './views/layouts/Footer.vue';

@Component({
  components: {
    Header,
    Content,
    Footer,
  },
})
export default class App extends Vue {
}
