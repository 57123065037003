




















































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { manageKycService, transactionHistoryService } from '../../services';
import ResponseCommon from '@/commons/ResponseCommon';
import { ManageKyc } from '@/models/ManageKyc';
import { data } from 'jquery';
import { KycDocumentEnum } from '@/models/enum/KycDocumentEnum';

@Component({
    components: {},
})
export default class ManageKYC extends Vue {

    private listError = [
        {'field': 'firstName', 'message': '', 'isError': false},
        {'field': 'lastName', 'message': '', 'isError': false},
        {'field': 'documentType', 'message': '', 'isError': false}
    ]

    private stepIsExecuting = 'step1';
    private srcImage1 = '';
    private srcImage2 = '';
    private srcImage3 = '';
    private srcImage4 = '';
    private srcImage5 = '';
    private manageKyc: ManageKyc = new ManageKyc();
    private isCreated = false;

    private checkbox1 = false;
    private checkbox2 = false;

    private listDocumentType = [{}];

    @Watch('$store.state.storedLang')
    private changeLang() {
        this.stepIsExecuting = 'step1';
        this.srcImage1 = '';
        this.srcImage2 = '';
        this.srcImage3 = '';
        this.srcImage4 = '';
        this.srcImage5 = '';
        this.manageKyc.fileImage1 = null;
        this.manageKyc.fileImage2 = null;
        this.manageKyc.fileImage3 = null;
        this.manageKyc.fileImage4 = null;
        this.manageKyc.fileImage5 = null;
        (this.$refs.image1 as any).value = '';
        (this.$refs.image2 as any).value = '';
        (this.$refs.image3 as any).value = '';
        (this.$refs.image4 as any).value = '';
        (this.$refs.image5 as any).value = '';
        this.initDocumentType();
    }

    private created() {
        this.manageKyc.documentType = '';
        // this.getCreatedKyc();
        this.initDocumentType();
    }

    private initDocumentType() {
        if(this.$store.state.storedLang === 'ja') {
            this.listDocumentType = [
                { 'value': '選択してください', 'disabled': true },
                { 'value': '運転免許証' },
                { 'value': '運転経歴証明書' },
                { 'value': 'マイナンバーカード' },
                { 'value': '身体障がい者手帳' },
                { 'value': '療育手帳' },
                { 'value': '精神障がい者保健福祉手帳' },
                { 'value': '健康保険証+補助書類' },
                { 'value': '日本国パスポート+補助書類' },
                { 'value': '住民基本台帳カード+補助書類' },
                { 'value': '在留カード（外国籍の場合）' },
                { 'value': '特別永住者証明書（外国籍の場合）' }
            ]
            this.manageKyc.documentType = '';
        }
        if(this.$store.state.storedLang === 'en') {
            this.listDocumentType = [
                { 'value': 'ID and address verification documents' }
            ]
            this.manageKyc.documentType = 'ID and address verification documents';
        }
    }

    private getCreatedKyc() {
        manageKycService.getCreatedKyc().then((response) => {
            if (response && response.data && response.data.data) {
                if (response.data.data.document === KycDocumentEnum.REQUEST_SENT) {
                    return;
                }
                this.stepIsExecuting = 'isCreated';
                this.manageKyc = response.data.data;
                this.srcImage1 = this.manageKyc.image1 || '';
                this.srcImage2 = this.manageKyc.image2 || '';
                this.srcImage3 = this.manageKyc.image3 || '';
                this.srcImage4 = this.manageKyc.image4 || '';
                this.srcImage5 = this.manageKyc.image5 || '';
            }
        });
    }

    private step(step: string) {
        switch(step) {
            case 'step1': this.stepIsExecuting = 'step1'; window.scrollTo(0,0); break;
            case 'step2': 
                if(this.manageKyc.documentType === '') {
                    alert("アップロードする書類を選択してください。");
                    window.scrollTo(0,0);
                    break;
                }
                if(this.manageKyc.fileImage1 === null || this.manageKyc.fileImage1 === undefined || this.manageKyc.fileImage1 === '') {
                    alert(this.$t('kyc_register.image1_required').toString());
                    window.scrollTo(0,0);
                    break;
                }
                this.stepIsExecuting = 'step2';
                window.scrollTo(0,0);
                break;
        }
    }

    private openUploadImage(image: string) {

        switch(image) {
            case 'image1': 
                (this.$refs.image1 as any).click();
                break;
            case 'image2': 
                (this.$refs.image2 as any).click();
                break;
            case 'image3': 
                (this.$refs.image3 as any).click();
                break;
            case 'image4': 
                (this.$refs.image4 as any).click();
                break;
            case 'image5': 
                (this.$refs.image5 as any).click();
                break;
        }
    }

    private changeFileImage(image: string) {
        
        switch(image) {
            case 'image1': 
                this.manageKyc.fileImage1 = (this.$refs.image1 as any).files[0];
                this.srcImage1 = this.manageKyc.fileImage1 ? URL.createObjectURL(this.manageKyc.fileImage1 as any) : ''
                break;
            case 'image2': 
                this.manageKyc.fileImage2 = (this.$refs.image2 as any).files[0];
                this.srcImage2 = this.manageKyc.fileImage2 ? URL.createObjectURL(this.manageKyc.fileImage2 as any) : ''
                break;
            case 'image3': 
                this.manageKyc.fileImage3 = (this.$refs.image3 as any).files[0];
                this.srcImage3 = this.manageKyc.fileImage3 ? URL.createObjectURL(this.manageKyc.fileImage3 as any) : ''
                break;
            case 'image4': 
                this.manageKyc.fileImage4 = (this.$refs.image4 as any).files[0];
                this.srcImage4 = this.manageKyc.fileImage4 ? URL.createObjectURL(this.manageKyc.fileImage4 as any) : ''
                break;
            case 'image5': 
                this.manageKyc.fileImage5 = (this.$refs.image5 as any).files[0];
                this.srcImage5 = this.manageKyc.fileImage5 ? URL.createObjectURL(this.manageKyc.fileImage5 as any) : ''
                break;
        }
    }

    private deleteImage(image: string) {
        switch(image) {
            case 'image1': 
                alert(this.$t('kyc_register.msg_delete_image1').toString());
                break;
            case 'image2': 
                this.manageKyc.fileImage2 = null
                this.srcImage2 = ''
                break;
            case 'image3': 
                this.manageKyc.fileImage3 = null
                this.srcImage3 = ''
                break;
            case 'image4': 
                this.manageKyc.fileImage4 = null
                this.srcImage4 = ''
                break;
            case 'image5': 
                this.manageKyc.fileImage5 = null
                this.srcImage5 = ''
                break;
        }
    }

    private register() {
        if (this.isCreated) {
            return;
        }
        if(this.$store.state.storedLang === 'ja') {
            if(!(this.checkbox1 && this.checkbox2)) {
                alert(this.$t('kyc_register.checkbox_required').toString());
                return;
            }
        } else {
            if(!(this.checkbox1)) {
                alert(this.$t('kyc_register.checkbox_required').toString());
                return;
            }
        }
        
        const formData = new FormData();
        formData.append('fileImage1', this.manageKyc.fileImage1!);
        formData.append('fileImage2', this.manageKyc.fileImage2!);
        formData.append('fileImage3', this.manageKyc.fileImage3!);
        formData.append('fileImage4', this.manageKyc.fileImage4!);
        formData.append('fileImage5', this.manageKyc.fileImage5!);
        formData.append('documentType', this.manageKyc.documentType || '');

        (this.$refs.btn_register_kyc as any).disabled = true;
        manageKycService
            .registerKyc(formData)
            .then((res: any) => {
                if(res.data && res.data.code === 0) {
                    this.stepIsExecuting = 'done';
                    window.scrollTo(0,0);
                }
            })
    }

    private backToTop() {
        window.location.replace('/')
    }

}
