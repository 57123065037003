import Vue from 'vue';

class LoginService extends Vue {
    public ROOT_URL = process.env.VUE_APP_API_URL + '/auth/authenticate';
    public token = 'x-auth-token';

    public isAuthenticated(): boolean {
        // TODO: need to verify from backend

        return localStorage[this.token] !== null
          && localStorage[this.token] !== undefined
          && localStorage[this.token] !== ''
          && localStorage[this.token] !== 'null';
    }

    public addToken(config: any): any {
        if (localStorage[this.token]) {
            config.headers[this.token] = localStorage[this.token];
        }
        return config;
    }

    public login(request: any): Promise<any> {
        return Vue.axios.post(this.ROOT_URL, request)
        .then((res: any) => {
            if (res.headers[this.token]) {
              // Success
              localStorage.setItem('jwttoken', res.headers[this.token]);
              localStorage[this.token] = res.headers[this.token];
              this.axios.defaults.headers.common.Authorization = 'jwttoken:' + res.headers[this.token];
              return true;
            }
            return false;
        });
    }

    public logout(): Promise<any> {
        // TODO: Server-side token handler
        localStorage.removeItem('jwttoken');
        localStorage.removeItem(this.token);
        delete this.axios.defaults.headers.common.Authorization;
        return Promise.resolve(true);
    }

    public getLogoutAllGame(): Promise<any> {
        // TODO: Server-side token handler
        return Vue.axios.get(process.env.VUE_APP_API_URL + '/webapi/shop/logout-all');
    }
}

export const loginService = new LoginService();
