import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

class CommonService extends Vue {
  public getCurrentTimeOfTheServer(): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(process.env.VUE_APP_API_URL + '/webapi/common/get-current-time-of-the-server');
  }
}

export const commonService = new CommonService();