
























































































































































import { Component, Vue } from 'vue-property-decorator';
import { userService, loginService, machineChannelService, transactionHistoryService, manageKycService } from '@/services';
import ResponseCommon from '@/commons/ResponseCommon';
import { modelService } from "@/services";
import RegisterMemberPopup from '@/views/register/RegisterMemberPopup.vue';

@Component({
  components: {
    RegisterMemberPopup,
  },
})
export default class Header extends Vue {
  public showMenuHamburger = false
  public nickName = '';
  public userId = 0;
  public currentUserBanlance = '';
  public isRegisterModal = false;
  public isLoginSuccess = false;
  public intervalid = 0;
  public active = "";
public dataChanguage =  {
    idUser: 0,
    lang: ''
  };

  public langs = [
    {
      text: '英語',
      code: 'en',
      flag: require('@/assets/top/img/lang_en.png'),
    },
    {
      text: '日本語',
      code: 'ja',
      flag: require('@/assets/top/img/lang_jp.png'),
    },
    // {
    //   text: 'タイ語',
    //   code: 'cn',
    //   flag: require('@/assets/top/img/lang_th.png'),
    // },
  ];

  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "../../assets/top/js/common.js"
    );
  //   plugin.async = true;
  //   document.head.appendChild(plugin);
 
  //   const plugin1 = document.createElement("script");
  //   plugin1.setAttribute(
  //     "src",
  //     "../../assets/top/js/common.js",
  //   );
  //   plugin1.async = true;
  //   document.head.appendChild(plugin1);
  }

  public created() {
    this.getUserProfile();
    // Reload info user in header every 10 seconds in 1 time
    this.intervalid = setInterval(() => {
      this.getUserProfile();
    }, 10000);
  }

  private openLogin() {
    this.$router.push({
      name: 'login',
      params: { redirect : this.$route.fullPath },
    });
  }

  private openTransaction() {
    manageKycService.getKYCInformation().then((res: any) => {
      if(res.data && res.data.code === ResponseCommon.CODE_SUCCESS) {
        this.getUserProfile();
        
        if(res.data.data.points) {
          if(res.data.data.information) {
            if(res.data.data.information.documentStatus === 'REQUEST_SENT') {
              alert(this.$t('kyc_register.request_sent').toString());
              this.$router.push({ path: '/manage-KYC' });
            } else if(res.data.data.information.kycStatus === 'DONE') {
              this.$router.push({ path: '/transaction' });
            } else {
              alert(this.$t('kyc_register.kyc_pending').toString());
            }
          } else {
            alert(this.$t('kyc_register.upload_documnent').toString());
            this.$router.push({ path: '/manage-KYC' });
          }
        } else {
          this.$router.push({ path: '/transaction' });
        }

        // if(res.data.data.isKyc && res.data.data.isKyc === true) {
        //   if(res.data.data.documentStatus) {
        //     if(res.data.data.documentStatus === 'REQUEST_SENT') {
        //       alert(this.$t('kyc_register.request_sent').toString());
        //       window.location.replace('/manage-KYC');
        //     } else {
        //       alert(this.$t('kyc_register.kyc_pending').toString());
        //     }
        //   } else {
        //     alert(this.$t('kyc_register.upload_documnent').toString());
        //     window.location.replace('/manage-KYC');
        //   }
        // } else {
        //   window.location.replace('/transaction');
        // }
      }
    })
  }

  public comingSoon(){
    alert("coming soon !!")
  }

  public loadPachi(value: number){
    if (value == 1) {
      this.$store.dispatch("setShopGame", "1");
    } else {
      this.$store.dispatch("setShopGame", "0");
    }
   
  }

  public showSp(){
    if (this.active == "active") {
      this.active = "";
    }else{
      this.active = "active";
    }
  }

  public onClickLogin() {
    this.$router.push({
      name: 'login',
      params: { action: 'login' },
    });
  }

  public onClickRegister() {
    this.isRegisterModal = true;
  }

  public closeRegisterModal() {
    this.isRegisterModal = false;
  }

  public onChangeLanguage(event: any) {
    const lang = event.target.getAttribute('value');
    localStorage.setItem('Language', lang);
    this.axios.defaults.headers.common.Language = lang;
    this.$store.dispatch('setLang', lang);
    this.dataChanguage.idUser = this.userId;
    this.dataChanguage.lang = lang;
    const jwttoken = localStorage.getItem('jwttoken');
    if (jwttoken) {
      userService.changeLanguage(this.dataChanguage).then();
    }
  }


  private formatPrice(value: any) {
    const val = (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private async getUserProfile() {
    if (this.axios.prototype.hasUserInfo()) {
      this.isLoginSuccess = true;
      userService.getUserProfile().then((response) => {
        if (response && response.data && response.data.data) {
          this.userId = response.data.data.id;
          this.nickName = response.data.data.nickName;
          this.currentUserBanlance = this.formatPrice(response.data.data.balance);
        }
                  // set language get from DB
        localStorage.setItem('Language', response.data.data.language);
        this.axios.defaults.headers.common.Language = response.data.data.language;
        this.$store.dispatch('setLang', response.data.data.language);

        // transactionHistoryService.isKyc().then((res: any) => {
        //   if (response && response.data.code === ResponseCommon.CODE_SUCCESS) {
        //     localStorage.setItem('isKyc', res.data.data);
        //   }
        // })

        localStorage.setItem('kyc', JSON.stringify(response.data.data.kyc));
        localStorage.setItem('lockAccount', JSON.stringify(response.data.data.lockAccount));

      }).catch(error => {
        this.isLoginSuccess = false;
      });
    } else {
      if (this.userId != 0) {
        await machineChannelService.removeMachineWaitingOfUserTimeout(this.userId).then((response) => {
          if (response && response.data.code === ResponseCommon.CODE_SUCCESS) {
            this.userId = 0;
          }
        });
      }
    }
  }

  public async logout() {
    this.$store.dispatch('setUser', {});
    await machineChannelService.removeUserFromChannel();
    // Logout
    await loginService.logout()
    .then((isSuccess) => {
      if (isSuccess) {
        window.location.href = "/";
      }
    });
  }
  
}
