
















































































import { Vue, Component } from 'vue-property-decorator';
import { gameService } from '@/services';
import ResponseCommon from '@/commons/ResponseCommon';
import { userCheatService, modelService } from '@/services';

@Component({
  components: {
  },
})
export default class FreePlayPage extends Vue {
  private isLogin?: boolean;
  private showModalBackToGame = false;
  private linkBackGame = '';

  private created() {
    if (this.axios.prototype.hasUserInfo()) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    if(this.isLogin) {
      modelService.showWarningPopup().then((response) => {
        if (response.data.code === ResponseCommon.CODE_SUCCESS) {
          this.showModalBackToGame = true;
          this.linkBackGame = response.data.data;
        }
      });
    }
  }

  private backToGame() {
    window.location.href = this.linkBackGame;
  }

  private async freePlayGame(idGame: number) {
    if(this.isLogin) {
      await userCheatService.checkLock().then((res: any) => {
        if(res.data.code === ResponseCommon.CODE_SUCCESS) {
          alert(this.$t('validation.game.user_locked').toString());
        } else {
          modelService.showWarningPopup().then((response) => {
            if (response.data.code === ResponseCommon.CODE_SUCCESS) {
              this.showModalBackToGame = true;
              this.linkBackGame = response.data.data;
            } else if(response.data.code === ResponseCommon.CODE_NOT_FOUND) {
              gameService.getLinkGame(idGame).then((res: any) => {
                if(res.data && res.data.code === ResponseCommon.CODE_SUCCESS) {
                  window.location.href = res.data.data
                }
              })
            }
          });
        }
      })
    } else {
      this.$router.push({
        name: 'login',
        params: { redirect : this.$route.fullPath },
      });
    }
  }
}
