




















































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { CountryCode, UserInfoPaystage } from '@/models';
import { userInfoPaystage } from '@/services';
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {}
})
export default class DepositWithCreditCardPage extends Vue {
  private localStorage = localStorage;
  private userInfo: UserInfoPaystage = new UserInfoPaystage();
  private listCountryCode: CountryCode[] = [];
  private showErrorMsg = false;
  private errorMsg = '';

  // @Watch('$store.state.storedLang')
  // private changeLang() {
  //   this.defaultCountryCode();
  // }

  private async changeZip() {
    if(this.userInfo.country === 'JP' && this.userInfo.zip && this.userInfo.zip.length === 7) {
      await userInfoPaystage
        .getStateAndCityByZipCode(this.userInfo.zip)
        .then((res: any) => {
          if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS && res.data.data) {
            this.userInfo.state = res.data.data.data[0].en.prefecture;
            if(res.data.data.data[0].en.address2 !== '') {
              res.data.data.data[0].en.address2 = res.data.data.data[0].en.address2 + ', ';
            }
            this.userInfo.city = res.data.data.data[0].en.address2 + res.data.data.data[0].en.address1;
          }
        })
    }
  }

  private async created() {
    await this.allCountryCode();
    await this.getUserInfo();
    await this.getEmailDefault();
  }

  private createOrUpdateUserInfo() {
    this.showErrorMsg = false;
    this.errorMsg = '';
    (this.$refs.userInfoPaystageButtonSubmit as any).disabled = true;
    userInfoPaystage
      .createOrUpdateUserInfoPaystage(this.userInfo)
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.$router.push({
            name: 'deposit-with-credit-card.receive-credit-card'
          });
        } else {
          this.showErrorMsg = true;
          this.errorMsg = res.data.data;
        }
      }).finally(() => (this.$refs.userInfoPaystageButtonSubmit as any).disabled = false)
  }

  private async allCountryCode() {
    await userInfoPaystage
      .getAllCountryCode()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.listCountryCode = res.data.data;
        }
      })
  }

  private async getUserInfo() {
    await userInfoPaystage
      .getUserInfoPaystage()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.userInfo = res.data.data;
        } else {
          this.defaultCountryCode();
        }
      })
  }

  private defaultCountryCode() {
    if(this.$store.state.storedLang === 'ja') {
      this.userInfo.country = this.listCountryCode.find((res: any) => res.code === 'JP')?.code;
      this.userInfo.internationalDialing = this.listCountryCode.find((res: any) => res.code === 'JP')?.internationalDialing;
    } else {
      this.userInfo.country = this.listCountryCode[0].code;
      this.userInfo.internationalDialing = this.listCountryCode[0].internationalDialing;
    }
  }

  private async getEmailDefault() {
    await userInfoPaystage
      .getEmail()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.userInfo.email = res.data.data;
        }
      })
  }

  private changeCountryCode() {
    this.userInfo.internationalDialing = this.listCountryCode.find((res: any) => res.code === this.userInfo.country)?.internationalDialing;
  }

  private backToTransactionMenuPage() {
    this.$router.push({
      name: 'transaction'
    });
  }
}
