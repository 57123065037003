export class DataTableRequest {
  public currentPage: number;
	public perPage: number;
	public filter: string;
	public sortBy: string;
	public sortDesc: boolean;

  constructor(init?: Partial<DataTableRequest>) {
    this.currentPage = 1;
    this.perPage = 10;
    this.filter = '';
    this.sortBy = '';
    this.sortDesc = true;
    Object.assign(this, init);
  }
}