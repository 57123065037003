






















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HowToPlay extends Vue {
  private isPaystage = false;
  private isBTSE = false;
}
