






















































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { modelService } from '@/services'
import ResponseCommon from '@/commons/ResponseCommon';

@Component
export default class TopPage extends Vue {
  private showModalBackToGame = false;
  private linkBackGame = '';

  private async created() {
      // await this.getMachinesByModel(this.modelId);
      // await this.getModel();
      // await this.getImgHeader();
      // Reload state machine every 5 minutes in 1 time
      // this.intervalid = setInterval(() => {
      // this.getMachinesByModel(this.modelId);
      // }, 300000);
  }
  private mounted() {
      const plugin = document.createElement("script");
      plugin.setAttribute(
          "src",
          "https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"
      );
      plugin.async = true;
      document.head.appendChild(plugin);
      
      if(localStorage.getItem('jwttoken') != null) {
          modelService.showWarningPopup().then((response) => {
              if (response.data.code === ResponseCommon.CODE_SUCCESS) {
                  this.showModalBackToGame = true;
                  this.linkBackGame = response.data.data;
              }
          });
      }
  }

  private backToGame() {
      this.showModalBackToGame = false;
      window.location.href = this.linkBackGame;
  }

  // private async getMachinesByModel(modelId: number) {
  //     document.getElementById('id1').style.color = 'red'
  //     collection.css("display", "none");
  //         $(".question__wrap").click(function () {
  //             $(".question__wrap").not(this).removeClass("open");
  //             $(".question__wrap").not(this).next().slideUp(300);
  //             $(this).toggleClass("open");
  //             $(this).next().slideToggle(300);
  //         });
   
     
  // }

  // private async getMachinesByModel(modelId: number) {
  //         $(".answer").css("display", "none");
  //         $(".question").click(function () {
  //             $(".question").not(this).removeClass("open");
  //             $(".question").not(this).next().slideUp(300);
  //             $(this).toggleClass("open");
  //             $(this).next().slideToggle(300);
  //         });
     
  // }

}

 // Scroll
 
