export class Model {
	public id?: number;
	public name?: string;
	public deviceNumber?: number;
	public state?: number;
	public unitPriceId?: number;
	public tagStatus?: number;
	public eventTagStatus?: number;
	public platform?: number;
	public startLink?: string;
	public imagePath?: string;
	public purchasePrice?: number;
	public cashingPrice?: number;
	constructor(init?: Partial<Model>) {
		Object.assign(this, init);
	}
}