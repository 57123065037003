import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

const URL_ENV = process.env.VUE_APP_API_URL + '/webapi/transaction-paystage';

class TransactionPaystage extends Vue {

  public createTransactionPaystage(data: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV, data);
  }

  public getTransactionPaystageByUserIdAndReferenceNo(data: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV + '/get-transaction-paystage-by-user-id-and-reference-no/' + data);
  }
}
export const transactionPaystage = new TransactionPaystage();
