import Vue from 'vue';

const REMOVE_USER_FROM_CHANNEL = process.env.VUE_APP_API_URL + "/webapi/machine-channel/remove-user-from-channel"
const CHECK_USED_MACHINE = process.env.VUE_APP_API_URL + "/webapi/machine-channel/check-used-machine"
const CHANGE_MACHINE_WAITING = process.env.VUE_APP_API_URL + "/webapi/machine-channel/change-machine-waiting"
const REMOVE_MACHINE_WAITING_OF_USER_TIMEOUT = process.env.VUE_APP_API_URL + "/webapi/machine-channel/remove-machine-waiting-of-user-timeout"
const CALL_API_DATA_MACHINE_GAME = process.env.VUE_APP_API_URL + "/webapi/machine-channel/call-api-data-machine-game"

class MachineChannelService extends Vue {

  public removeUserFromChannel() {
    return this.axios.put(REMOVE_USER_FROM_CHANNEL);
  }

  public checkUsedMachine(request: any) {
    return this.axios.get(CHECK_USED_MACHINE, { params: request });
  }

  public changeMachineWaiting(request: any) {
    return this.axios.get(CHANGE_MACHINE_WAITING, {params: request});
  }

  public removeMachineWaitingOfUserTimeout(userId: number) {
    return this.axios.get(REMOVE_MACHINE_WAITING_OF_USER_TIMEOUT + '/' + userId);
  }

  public callApiDataMachineGame(request: any) {
    return this.axios.get(CALL_API_DATA_MACHINE_GAME, { params: request });
  }
}

export const machineChannelService = new MachineChannelService();
