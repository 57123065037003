









































import { Component, Vue } from 'vue-property-decorator';
import { userService } from '@/services';
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {
  },
})
export default class ForgotPassword extends Vue {
  private email = '';
  private token: any;
  private step = '';
  private sendMailError = false;

  private created() {
    this.token = this.$route.query.token;
    if(this.token) {
      userService.confirmResetPassword({ token: this.token.toString() })
        .then((res: any) => {
          if(res.data.code === ResponseCodeEnum.CODE_EXPIRED) {
            this.step = 'expried';
          } else if(res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
            this.step = 'sendMailSuccess';
          } else {
            this.step = 'forgot';
          }
        })
    } else {
      this.step = 'forgot';
    }
  }

  private sendMailForgotPassword() {
    this.sendMailError = false;
    (this.$refs.forgotPasswordBtn as any).disabled = true;
    userService.forgotPassword({
      email: this.email
    }).then((res: any) => {
      if(res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
        this.step = 'sendMailSuccess';
      } else {
        this.sendMailError = true;
      }
    }).finally(() => (this.$refs.forgotPasswordBtn as any).disabled = false)
  }
}
