





































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '../../models';
import { userService } from '../../services';
import ResponseCommon from '../../commons/ResponseCommon';
// import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue';
// import CookiePolicyPage from '@/views/CookiePolicyPage.vue';
// import CodeOfConduct from '@/views/code-of-conduct/CodeOfConduct.vue';
// import TermAndConditionsPage from '@/views/termandconditions/TermAndConditionsPage.vue';
import { UserProfileModel } from '../../models/UserProfileModel';

@Component({
  name: 'RegisterMemberPopup',
  components: {
    // PrivacyPolicyPage,
    // TermAndConditionsPage,
    // CookiePolicyPage,
    // CodeOfConduct,
  },
})
export default class extends Vue {
  @Prop() public id!: string;
  private isLoading = false;
  private userModel: User = new User();
  private show = true;
  private checkYearOld = false;
  private isPrivacyPolicyModal = false;
  private isCookiePolicyModal = false;
  private isCodeOfConductModal = false;
  private isTermAndConditionModal = false;
  private countryList = [];
  private clearUserNameError = true;
  private clearNickNameError = true;
  private clearEmailError = true;
  private clearEmailConfirmError = true;
  private clearPasswordError = true;
  private clearPasswordConfirmError = true;
  private emailExisted = false;
  private userNameExisted = false;
  private nickNameExisted = false;
  private regexUserName = /^[a-zA-Z0-9_]*$/;
  private regexNickNamePassword = /^[a-zA-Z0-9]+$/;
  private emailConfirm = '';
  private passwordConfirm = '';
  private userNameValidationText = '';
  private nickNameValidationText = '';
  private emailValidationText = '';
  private emailConfirmValidationText = '';
  private passwordValidationText = '';
  private passwordConfirmValidationText = '';
  private checkYearOldText = '';

  private blurInput(value: string) {
    switch (value) {
      case 'username':
        this.clearUserNameError = false;
        break;
      case 'nickname':
        this.clearNickNameError = false;
        break;
      case 'email':
        this.clearEmailError = false;
        break;
      case 'emailconfirm':
        this.clearEmailConfirmError = false;
        break;
      case 'password':
        this.clearPasswordError = false;
        break;
      case 'passwordconfirm':
        this.clearPasswordConfirmError = false;
        break;
    }
  }

  private checkExistEmail() {
    const userProfileModel = new UserProfileModel();
    userProfileModel.email = this.userModel.email;
    userService.checkExistEmail(userProfileModel).then((response: any) => {
      if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
        this.emailExisted = true;
        this.emailValidationText = this.$t(response.data.data).toString();
      } else {
        this.emailExisted = false;
      }
    });
  }

  private checkExistUserName() {
    const userProfileModel = new UserProfileModel();
    userProfileModel.userName = this.userModel.userName;
    userService.checkExistUserName(userProfileModel).then((response: any) => {
      if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
        this.userNameExisted = true;
        this.userNameValidationText = this.$t(response.data.data).toString();
      } else {
        this.userNameExisted = false;
      }
    });
  }

    private checkExistNickName() {
    const userProfileModel = new UserProfileModel();
    userProfileModel.nickName = this.userModel.nickName;
    userService.checkExistNickName(userProfileModel).then((response: any) => {
      if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
        this.nickNameExisted = true;
        this.nickNameValidationText = this.$t(response.data.data).toString();
      } else {
        this.nickNameExisted = false;
      }
    });
  }

  private onComplete() {
    this.isLoading = true;
    userService
      .registerUser(this.userModel)
      .then((response) => {
        if (response.data.code === ResponseCommon.CODE_SUCCESS) {
          this.$bvModal
            .msgBoxOk(this.$t('view.registermember.verifyEmail').toString(), {noCloseOnBackdrop: true})
            .then(() => {
              this.$emit('closeRegisterModal');
              this.userModel = new User();
              if (this.$router.currentRoute.name !== 'login') {
                this.$router.push('/login');
              }
            });
        }
      })
      .finally(() => (this.isLoading = false));
  }

  private openPrivacyPolicyModal() {
    this.isPrivacyPolicyModal = true;
  }

  private openCookiePolicyModal() {
    this.isCookiePolicyModal = true;
  }

  private openCodeOfConductModal() {
    this.isCodeOfConductModal = true;
  }

  private openTermAndConditionModal() {
    this.isTermAndConditionModal = true;
  }

  private userNameValidation() {
    if (this.clearUserNameError) {
      return;
    }
    if (!(this.userModel.userName.length > 0)) {
      this.userNameValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (!this.regexUserName.test(this.userModel.userName)) {
      this.userNameValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
      return false;
    } else if (!(this.userModel.userName.length <= 12)) {
      this.userNameValidationText = this.$t('view.registermember.valid.usernamemax').toString();
      return false;
    } else if (this.userNameExisted) {
      return false;
    }
    return true;
  }

  private nickNameValidation() {
    if (this.clearNickNameError) {
      return;
    }
    if (!(this.userModel.nickName.length > 0)) {
      this.nickNameValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (!this.regexNickNamePassword.test(this.userModel.nickName)) {
      this.nickNameValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
      return false;
    } else if (!(this.userModel.nickName.length <= 12)) {
      this.nickNameValidationText = this.$t('view.registermember.valid.usernamemax').toString();
      return false;
    } else if (this.nickNameExisted) {
      return false;
    }
    return true;
  }

  private emailValidation() {
    const regexpEmail = /^[a-zA-Z0-9\-!#$%&`*+/=?^{|}~._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/gm;
    if (this.clearEmailError) {
      return;
    }
    if (!(this.userModel.email.length > 0)) {
      this.emailValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (!regexpEmail.test(this.userModel.email)) {
      this.emailValidationText = this.$t('view.registermember.valid.email').toString();
      return false;
    } else if (this.emailExisted) {
      return false;
    }
    return true;
  }

  private emailConfirmValid() {
    if (this.clearEmailConfirmError) {
      return;
    }
    if (!(this.emailConfirm.length > 0)) {
      this.emailConfirmValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (this.emailConfirm !== this.userModel.email) {
      this.emailConfirmValidationText = this.$t('view.registermember.valid.emailconfirm').toString();
      return false;
    }
    return true;
  }

  private passwordValidation() {
    if (this.clearPasswordError) {
      return;
    }
    if (!(this.userModel.passWord.length > 0)) {
      this.passwordValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (!this.regexNickNamePassword.test(this.userModel.passWord)) {
      this.passwordValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
      return false;
    } else if (!(this.userModel.passWord.length > 5)) {
      this.passwordValidationText = this.$t('view.registermember.valid.passwordmin').toString();
      return false;
    }
    return true;
  }

  private passwordConfirmValid() {
    if (this.clearPasswordConfirmError) {
      return;
    }
    if (!(this.passwordConfirm.length > 0)) {
      this.passwordConfirmValidationText = this.$t('validation.input.required').toString();
      return false;
    } else if (this.passwordConfirm !== this.userModel.passWord) {
      this.passwordConfirmValidationText = this.$t('view.registermember.valid.passwordconfirm').toString();
      return false;
    }
    return true;
  }

  private checkYearOldValid() {
    if (
      this.userNameValidation() &&
      this.nickNameValidation() &&
      this.emailValidation() &&
      this.emailConfirmValid() &&
      this.passwordValidation() &&
      this.passwordConfirmValid()
    ) {
      if (!this.checkYearOld) {
        this.checkYearOldText = this.$t('view.registermember.valid.checkyearold').toString();
        return false;
      }
      this.checkYearOldText = '';
      return true;
    }
    return;
  }

  private validateFirstStep() {
    this.clearUserNameError = false;
    this.clearNickNameError = false;
    this.clearEmailError = false;
    this.clearEmailConfirmError = false;
    this.clearPasswordError = false;
    this.clearPasswordConfirmError = false;
    if (
      this.userNameValidation() &&
      this.nickNameValidation() &&
      this.emailValidation() &&
      this.emailConfirmValid() &&
      this.passwordValidation() &&
      this.passwordConfirmValid()
    ) {
      return true;
    }
    return false;
  }
}
