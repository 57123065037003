import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';
import { ContactModel } from '@/models/ContactModel';
class ContactService extends Vue {
  public sendContact(data: ContactModel): AxiosPromise<Response<ContactModel>> {
    return this.axios.post<Response<ContactModel>>( process.env.VUE_APP_API_URL + '/webapi/contact', data);
  }
}

export const contactService = new ContactService();
