































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { transactionBTSEService } from '@/services'
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';
import moment from 'moment';
import router from '@/router';

@Component({
  components: {}
})
export default class DepositWithCryptoConfirm extends Vue {
  private amount = 0;
  private step = 1;
  private amountReceive = 0;
  private paymentFee = 0;
  private totalDeposit = 0;
  private fee = 0.05;
  private showCheckValid = false;
  private msgErrorCheckValid = '';

  private coin: any = '';
  private protocol: any = '';

  private rate = -1;

  private createdDate = '';
  private createdDateTimeZone = '';

  private previewTotalDeposit = 0;

  private qrCode = '';
  private txtAddress = '';
  private transactionId = -1;
  private isBtnDeposit = false;
  private isBtnConfirmDeposit = false;

  private errorMessage = '';

  private created() {
    this.coin = this.$route.query.coin ? this.$route.query.coin : '';
    this.protocol = this.$route.query.protocol ? this.$route.query.protocol : '';
    this.getRate();
  }

  private getRate() {
    transactionBTSEService.getRate(this.coin, this.protocol)
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          if(res.data.data.code === 1) {
            this.rate = res.data.data.data.rate;
          }
        }
      })
  }

  private changeAmount() {
    this.amountReceive = (this.amount / this.rate);
    this.paymentFee = this.amountReceive * this.fee;
    this.totalDeposit = this.amountReceive + this.paymentFee;
    
    this.amountReceive = Number(this.coin === 'USDT' ? this.roundUp(this.amountReceive.toFixed(7), 4) : this.amountReceive.toFixed(7));
    this.paymentFee = Number(this.coin === 'USDT' ? this.roundUp(this.paymentFee.toFixed(7), 4) : this.paymentFee.toFixed(7));
    this.totalDeposit = Number(this.coin === 'USDT' ? this.roundUp(this.totalDeposit.toFixed(7), 4) : this.totalDeposit.toFixed(7));
  }

  private roundUp(value: any, decimals: any) {
    const factor = Math.pow(10, decimals);
    return (Math.ceil(value * factor) / factor).toFixed(decimals);
  }

  private deposit() {
    this.isBtnDeposit = true
    this.showCheckValid = false;
    this.msgErrorCheckValid = '';
    if (this.amount < 30 || this.amount > 3000) {
      this.showCheckValid = true;
      if (this.amount < 1) {
        this.msgErrorCheckValid = 'deposit_with_crypto.confirm.error_msg.amount_is_empty';
      } else {
        this.msgErrorCheckValid = 'deposit_with_crypto.confirm.error_msg.amount_between';
      }
      this.isBtnDeposit = false
    } else {
      transactionBTSEService.created({
        coin: this.coin,
        protocol: this.protocol,
        point: this.amount,
        utcTime: moment().format('YYYY/MM/DD HH:mm:ss (Z)')
      }).then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS && res.data.data.success) {
          if (res.data.data.language === 'ja') {
            const dt = new Date(res.data.data.createdDate);
            dt.setTime(dt.getTime() + dt.getTimezoneOffset()*60*1000 + 540*60*1000);
            
            this.createdDate = moment(dt).format('YYYY/MM/DD HH:mm');
            this.createdDateTimeZone = '(JST)';
          } else {
            this.createdDate = moment(res.data.data.createdDate).utc().format('YYYY/MM/DD HH:mm');
            this.createdDateTimeZone = '(UTC)';
          }

          this.previewTotalDeposit = res.data.data.amount;
          this.txtAddress = res.data.data.address;
          this.qrCode = 'data:image/png;base64, ' + res.data.data.base64ImageQRCode;
          this.transactionId = res.data.data.transactionId;
          this.step = 2;
        } else {
          this.step = -1;
          this.errorMessage = res.data.data.errorMessage;
        }
      }).finally(() => this.isBtnDeposit = false)
    }
  }

  private changeAmountBtn() {
    this.amount = 0;
    this.paymentFee = 0;
    this.amountReceive = 0;
    this.totalDeposit = 0;
    this.getRate();
    this.step = 1;
  }

  private confirmDeposit() {
    this.isBtnConfirmDeposit = true;
    if(this.transactionId) {
      transactionBTSEService.updatePending(this.transactionId)
        .then((res: any) => {
          if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
            router.push({ path: '/transaction-history' })
          }
        }).finally(() => this.isBtnConfirmDeposit = false)
    }
  }

  private contactUs() {
    this.$router.push({
      name: 'contactUs',
      query: {
        message: this.$t(this.errorMessage).toString()
      }
    });
  }

  private async copyAddress() {
    (this.$refs.txtCopy as any).classList.add('flashBG');
    await navigator.clipboard.writeText(this.txtAddress);
    setTimeout(() => {
      (this.$refs.txtCopy as any).classList.remove('flashBG');
    }, 1000);
  }

  private cancelDeposit() {
    this.$router.push({
      path: '/deposit-with-crypto'
    });
  }
}
