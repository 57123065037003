




























































































import { Vue, Component } from 'vue-property-decorator';
import { playHistoryService } from '@/services';
import moment, { tz } from "moment-timezone";
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';
import { DataTableRequest } from '@/models/DataTableRequest';
import { PlayHistoryModel } from '@/models/PlayHistoryModel';

@Component({
  components: {}
})
export default class TransactionHistoryPage extends Vue {
  private pageCount = 1;
  private playHistoryList: PlayHistoryModel[] = [];
  private dataTableRequest: DataTableRequest = new DataTableRequest();
  private moment = moment;

  private showDtail(id: string) {
    const trDetail1 = document.getElementById('trDetail1-' + id)
    trDetail1!.style.display = trDetail1!.style.display === 'none' ? '' : 'none';
    const trDetail2 = document.getElementById('trDetail2-' + id)
    trDetail2!.style.display = trDetail2!.style.display === 'none' ? '' : 'none';
    const tdIcon = document.getElementById('tdIcon-' + id)
    tdIcon!.innerHTML = trDetail1!.style.display && trDetail2!.style.display ? '▼' : '▲'
  }

  private created() {
    this.reloadTable();
  }

  private async reloadTable() {
    this.dataTableRequest.sortBy ='playTime';
    await playHistoryService.getAllByCurrentLoginId(this.dataTableRequest).then((res: any) => {
      if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
        this.playHistoryList = res.data.items;
        this.pageCount = res.data.totalRows / this.dataTableRequest.perPage;
      }
    })
  }

  private convertTimeZone(datetime: any) {
    if (datetime) {
      const d = new Date(datetime);
      return moment(d).format("YYYY-MM-DD HH:mm:ss (Z)");
    }
  }

  private changePerPage() {
    this.dataTableRequest.currentPage = 1;
    this.reloadTable();
  }
}
