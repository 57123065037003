var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_vm._m(0),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"user-profile"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.login_id').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":"","disabled":"","value":_vm.loginID}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.email_address').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":"","disabled":"","value":_vm.email}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.point').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":"","disabled":"","value":_vm.balance}})],1)]),_c('a',{staticClass:"button-point-charge",attrs:{"href":"/transaction"}},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.btn_deposit').toString())+" ")]),_c('a',{staticClass:"button-point-charge button-play-history",attrs:{"href":"/play-history"}},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.btn_play_history').toString())+" ")]),_c('a',{staticClass:"button-point-charge button-play-history",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.registerKYC()}}},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.btn_manage_kYC').toString())+" ")])])]),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"change-user-profile"},[_c('div',[(_vm.alertMsg.nickName.status && _vm.alertMsg.nickName.msg)?_c('div',{class:"alert alert-dismissible fade "
            + "" + (_vm.alertMsg.nickName.status && _vm.alertMsg.nickName.msg ? 'show ' : '')
            + "" + (_vm.alertMsg.nickName.status === 'success' ? 'alert-success' : _vm.alertMsg.nickName.status === 'error' ? 'alert-danger' : ''),attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alertMsg.nickName.msg))+" "),_c('button',{staticClass:"close btn",staticStyle:{"margin-top":"0.9% !important"},attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"},on:{"click":function () { _vm.alertMsg.nickName.status = ''; _vm.alertMsg.nickName.msg = ''}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.nick_name').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":""},model:{value:(_vm.user.nickName),callback:function ($$v) {_vm.$set(_vm.user, "nickName", $$v)},expression:"user.nickName"}})],1)]),_c('div',{staticClass:"form-button-update"},[_c('button',{staticClass:"button-update",on:{"click":_vm.updateNickName}},[_vm._v(_vm._s(_vm.$t('user_profile_page.btn_update').toString()))])])]),_c('div',[(_vm.alertMsg.email.status && _vm.alertMsg.email.msg)?_c('div',{class:"alert alert-dismissible fade "
            + "" + (_vm.alertMsg.email.status && _vm.alertMsg.email.msg ? 'show ' : '')
            + "" + (_vm.alertMsg.email.status === 'success' ? 'alert-success' : _vm.alertMsg.email.status === 'error' ? 'alert-danger' : ''),attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alertMsg.email.msg))+" "),_c('button',{staticClass:"close btn",staticStyle:{"margin-top":"0.9% !important"},attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"},on:{"click":function () { _vm.alertMsg.email.status = ''; _vm.alertMsg.email.msg = ''}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.email_update').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":""},model:{value:(_vm.emailUpdate),callback:function ($$v) {_vm.emailUpdate=$$v},expression:"emailUpdate"}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.confirm_email').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":""},model:{value:(_vm.emailUpdateConfirm),callback:function ($$v) {_vm.emailUpdateConfirm=$$v},expression:"emailUpdateConfirm"}})],1)]),_c('div',{staticClass:"form-button-update"},[_c('button',{staticClass:"button-update",on:{"click":_vm.updateEmail}},[_vm._v(_vm._s(_vm.$t('user_profile_page.btn_update').toString()))])])]),_c('div',[(_vm.alertMsg.password.status && _vm.alertMsg.password.msg)?_c('div',{class:"alert alert-dismissible fade "
            + "" + (_vm.alertMsg.password.status && _vm.alertMsg.password.msg ? 'show ' : '')
            + "" + (_vm.alertMsg.password.status === 'success' ? 'alert-success' : _vm.alertMsg.password.status === 'error' ? 'alert-danger' : ''),attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alertMsg.password.msg))+" "),_c('button',{staticClass:"close btn",staticStyle:{"margin-top":"0.9% !important"},attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"},on:{"click":function () { _vm.alertMsg.password.status = ''; _vm.alertMsg.password.msg = ''}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.password').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"password","placeholder":"","required":""},model:{value:(_vm.passwordUpdate),callback:function ($$v) {_vm.passwordUpdate=$$v},expression:"passwordUpdate"}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.confirm_password').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"password","placeholder":"","required":""},model:{value:(_vm.passwordUpdateConfirm),callback:function ($$v) {_vm.passwordUpdateConfirm=$$v},expression:"passwordUpdateConfirm"}})],1)]),_c('div',{staticClass:"form-button-update"},[_c('button',{staticClass:"button-update",on:{"click":_vm.updatePassword}},[_vm._v(_vm._s(_vm.$t('user_profile_page.btn_update').toString()))])])]),_c('div',[(_vm.alertMsg.mobileNumber.status && _vm.alertMsg.mobileNumber.msg)?_c('div',{class:"alert alert-dismissible fade "
            + "" + (_vm.alertMsg.mobileNumber.status && _vm.alertMsg.mobileNumber.msg ? 'show ' : '')
            + "" + (_vm.alertMsg.mobileNumber.status === 'success' ? 'alert-success' : _vm.alertMsg.mobileNumber.status === 'error' ? 'alert-danger' : ''),attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alertMsg.mobileNumber.msg))+" "),_c('button',{staticClass:"close btn",staticStyle:{"margin-top":"0.9% !important"},attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"},on:{"click":function () { _vm.alertMsg.mobileNumber.status = ''; _vm.alertMsg.mobileNumber.msg = ''}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v(" "+_vm._s(_vm.$t('user_profile_page.mobile_number').toString())+" ")]),_c('div',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":""},model:{value:(_vm.user.mobileNumber),callback:function ($$v) {_vm.$set(_vm.user, "mobileNumber", $$v)},expression:"user.mobileNumber"}})],1)]),_c('div',{staticClass:"form-button-update"},[_c('button',{staticClass:"button-update",staticStyle:{"margin-bottom":"0"},on:{"click":_vm.updateMobileNumber}},[_vm._v(_vm._s(_vm.$t('user_profile_page.btn_update').toString()))])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('span',[_vm._v("My page")])])}]

export { render, staticRenderFns }