











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Machine } from '@/models';
import { userCheatService } from '@/services/UserCheatService';
import { machineChannelService } from '@/services/MachineChannelService';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {},
})
export default class MachineView extends Vue {
  @Prop() private machine!: Machine;
  @Prop() private floor!: number;
  @Prop() private index!: number;
  private isLogin?: boolean;
  private images: string[] = [
    '@/assets/images/machine/play_1.png',
    '@/assets/images/machine/play_2.png',
    '@/assets/images/machine/play_3.png',
    '@/assets/images/machine/play_4.png',
    '@/assets/images/machine/play_5.png',
    '@/assets/images/machine/play_6.png',
    '@/assets/images/machine/play_7.png',
    '@/assets/images/machine/play_8.png',
    '@/assets/images/machine/play_9.png',
    // '@/assets/images/machine/play_10.png',
  ];
  private rdImage = 1;
  private params = {
    machineId: '',
    modelId: '',
    channelId: '',
  };

  private imageLeftPC = 0;
  private imageTopPC = 0;

  private imageLeftSP = 0;
  private imageTopSP = 0;

  private imageTagLeftPC = 0;
  private imageTagTopPC = 0;

  private imageEventTagTopPC = 0;
  private imageEventTagLeftPC = 0;

  private widthTagPC = 0;
  private widthEventTagPC = 0;

  private imageTagLeftSP = 0;
  private imageTagTopSP = 0;

  private imageEventTagTopSP = 0;
  private imageEventTagLeftSP = 0;

  private widthTagSP = 0;
  private widthEventTagSP = 0;

  public created() {
    this.imageLeftPC = 7 + this.index * 8.5;
    this.imageLeftSP = 15 + this.index * 13.5;
    this.imageTopPC = 6;
    this.imageTopSP = 14;

    this.imageEventTagLeftPC = this.imageLeftPC + 4.29;
    this.imageEventTagTopPC = this.imageTopPC + 3.2;
    this.imageTagLeftPC = this.imageLeftPC + 4.29;
    this.imageTagTopPC = this.machine.tagStatus === 1 ? this.imageEventTagTopPC + 6.8 : this.imageEventTagTopPC;
    this.widthTagPC = 5;
    this.widthEventTagPC = 5;

    this.imageEventTagLeftSP = this.imageLeftSP + 6.8;
    this.imageEventTagTopSP = this.imageTopSP + 3.4;
    this.imageTagLeftSP = this.imageLeftSP + 6.8;
    this.imageTagTopSP = this.imageEventTagTopSP + 5.7;
    this.widthTagSP = 8;
    this.widthEventTagSP = 8;

    // Display random image charactor
    this.rdImage = Math.floor(Math.random() * this.images.length) + 1;
    if (this.axios.prototype.hasUserInfo()) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

  // Open game Check User Locked
  private async openGame() {
    this.$emit("send-machine", this.machine);
    this.$emit("openPopup");
    // Check locked user
    // await userCheatService.checkLock().then((responseUserCheat) => {
    //   if (responseUserCheat.data.code === ResponseCommon.CODE_SUCCESS) {
    //     alert(this.$t('validation.game.user_locked').toString());
    //   } else if (responseUserCheat.data.code === ResponseCommon.CODE_INVALID
    //       && this.machine.id && this.machine.modelId && this.machine.channelId) {

    //     // check used machine
    //     this.params.machineId = this.machine.id.toString();
    //     this.params.modelId = this.machine.modelId.toString();
    //     this.params.channelId = this.machine.channelId.toString();
    //     machineChannelService.checkUsedMachine(this.params).then((responseMachineChannel) => {
    //       if (responseMachineChannel.data.code === ResponseCommon.CODE_SUCCESS) {
    //         // Set state machine is waiting when redirect to game
    //         machineChannelService.changeMachineWaiting(this.params).then((response) => {
    //           if (response.data.code === ResponseCommon.CODE_SUCCESS && this.machine && this.machine.gameDetail) {
    //             // Open game
    //               window.location.href = this.machine.gameDetail.startLink
    //               + '?model_id=' + this.machine.modelId
    //               + '&channel_id=' + this.machine.channelId
    //               + '&machine_id=' + this.machine.id;
    //           }
    //         });
    //       } else if (responseMachineChannel.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
    //         alert(this.$t('validation.game.used_machine').toString());
    //       }
    //     });
    //   }
    // });
  }
}
