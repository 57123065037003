




















































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { User } from '../../models';
import { userService } from '../../services';
import ResponseCommon from '../../commons/ResponseCommon';
import { UserProfileModel } from '../../models/UserProfileModel';
import moment from 'moment';
import Cookies from 'js-cookie'
import $ from 'jquery';
import 'daterangepicker';

@Component({
  components: {
  },
})
export default class Register extends Vue {
    private isLoading = false;
    private userModel: User = new User();
    private show = true;
    private checkYearOld = false;
    private checkRules = false;
    private isPrivacyPolicyModal = false;
    private isCookiePolicyModal = false;
    private isCodeOfConductModal = false;
    private isTermAndConditionModal = false;
    private countryList = [];
    private clearUserNameError = true;
    private clearNickNameError = true;
    private clearEmailError = true;
    private clearEmailConfirmError = true;
    private clearPasswordError = true;
    private clearPasswordConfirmError = true;
    private emailExisted = false;
    private userNameExisted = false;
    private mobilePhoneExisted = false;
    private nickNameExisted = false;
    private firstlastExisted = false;
    private regexmobilePhone = /^[0-9０-９]+$/;
    private regexUserName = /^[a-zA-Z0-9_]*$/;
    private regexNickNamePassword = /^[ぁ-んァ-ン一-龥a-zA-Z0-9]+$/;
    private emailConfirm = '';
    private passwordConfirm = '';
    private userNameValidationText = '';
    private nickNameValidationText = '';
    private emailValidationText = '';
    private emailConfirmValidationText = '';
    private passwordValidationText = '';
    private passwordConfirmValidationText = '';
    private checkYearOldText = '';
    private checkRulesText = '';
    private success = false;
    private errorYearOld = true;
    private errorRules = true;

    private clearMobileNumberError = true;
    private mobileNumberValidationText = '';

    private clearFirstNameError = true;
    private firstNameValidationText = '';

    private clearLastNameError = true;
    private lastNameValidationText = '';

    private clearAddressError = true;
    private addressValidationText = '';

    private clearDateOfBirthError = true;
    private dateOfBirthValidationText = '';

    private mounted() {
        ($(this.$refs.daterangepicker) as any).daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            autoUpdateInput: false,
            locale: {
                format: 'YYYY-MM-DD',
                cancelLabel: 'Clear'
            },
            minYear: 1901,
            maxYear: parseInt(moment().format('YYYY'),10)
        }, (start: any) => {
            this.userModel.birthday = start.format('YYYY-MM-DD');
        });

        $(this.$refs.daterangepicker).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('YYYY-MM-DD'));
        });
    }

    private blurInput(value: string) {
        switch (value) {
        case 'username':
            this.clearUserNameError = false;
            break;
        case 'nickname':
            this.clearNickNameError = false;
            break;
        case 'email':
            this.clearEmailError = false;
            break;
        case 'emailconfirm':
            this.clearEmailConfirmError = false;
            break;
        case 'password':
            this.clearPasswordError = false;
            break;
        case 'passwordconfirm':
            this.clearPasswordConfirmError = false;
            break;
        case 'mobileNumber':
            this.clearMobileNumberError = false;
            break;
        
        case 'firstName':
            this.clearFirstNameError = false;
            break;
        case 'lastName':
            this.clearLastNameError = false;
            break;
        case 'address':
            this.clearAddressError = false;
            break;
        case 'dateOfBirth':
            this.clearDateOfBirthError = false;
            break;
        }
    }

    private mobileNumberValidation() {
        if (this.clearMobileNumberError) {
            return;
        }
        if (!(this.userModel.mobileNumber.length > 0)) {
            this.mobileNumberValidationText = this.$t('validation.input.required').toString();
            return false;
        }
        if (!this.regexmobilePhone.test(this.userModel.mobileNumber)) {
            this.mobileNumberValidationText = this.$t('view.registermember.valid.mobilephoneregex').toString();
            return false;
        }
        if (this.mobilePhoneExisted) {
            return false;
        }
        return true;
    }

    private firstNameValidation() {
        if (this.clearFirstNameError) {
            return;
        }
        if (!(this.userModel.firstName.length > 0)) {
            this.firstNameValidationText = this.$t('validation.input.required').toString();
            return false;
        }
        if (this.firstlastExisted) {
            return false;
        }
        return true;
    }

    private lastNameValidation() {
        if (this.clearLastNameError) {
            return;
        }
        if (!(this.userModel.lastName.length > 0)) {
            this.lastNameValidationText = this.$t('validation.input.required').toString();
            return false;
        }
        if (this.firstlastExisted) {
            return false;
        }
        return true;
    }

    private addressValidation() {
        if (this.clearAddressError) {
            return;
        }
        if (!(this.userModel.address.length > 0)) {
            this.addressValidationText = this.$t('validation.input.required').toString();
            return false;
        }
        return true;
    }

    private checkDate() {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!this.userModel.birthday.match(regex)) {
            return false;
        }

        const parts = this.userModel.birthday.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const day = parseInt(parts[2], 10);

        if (month < 1 || month > 12) {
            return false;
        }

        const daysInMonth = [31, (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (day < 1 || day > daysInMonth[month - 1]) {
            return false;
        }

        return true;
    }

    private dateOfBirthValidation() {
        if (this.clearDateOfBirthError) {
            return;
        }
        if (!(this.userModel.birthday.length > 0)) {
            this.dateOfBirthValidationText = this.$t('validation.input.required').toString();
            return false;
        }
        if (!this.checkDate()) {
            this.dateOfBirthValidationText = this.$t('view.registermember.valid.birthdayRegex').toString();
            return false;
        }
        return true;
    }

    private checkRegister() {
        if(this.checkYearOld) {
            return false;
        }
        if(this.checkRules) {
            return false;
        }
        return true;
    }

    private checkExistEmail() {
        const userProfileModel = new UserProfileModel();
        userProfileModel.email = this.userModel.email;
        userService.checkExistEmail(userProfileModel).then((response: any) => {
        if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
            this.emailExisted = true;
            this.emailValidationText = this.$t(response.data.data).toString();
        } else {
            this.emailExisted = false;
        }
        });
    }

    private checkExistUserName() {
        const userProfileModel = new UserProfileModel();
        userProfileModel.userName = this.userModel.userName;
        userService.checkExistUserName(userProfileModel).then((response: any) => {
        if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
            this.userNameExisted = true;
            this.userNameValidationText = this.$t(response.data.data).toString();
        } else {
            this.userNameExisted = false;
        }
        });
    }

    private checkExistMobilePhone() {
        const userProfileModel = new UserProfileModel();
        userProfileModel.mobileNumber = this.userModel.mobileNumber;
        userService.checkExistMobilePhone(userProfileModel).then((response: any) => {
        if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
            this.mobilePhoneExisted = true;
            this.mobileNumberValidationText = this.$t(response.data.data).toString();
        } else {
            this.mobilePhoneExisted = false;
        }
        });
    }

    private checkExistNickName() {
        const userProfileModel = new UserProfileModel();
        userProfileModel.nickName = this.userModel.nickName;
        userService.checkExistNickName(userProfileModel).then((response: any) => {
        if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
            this.nickNameExisted = true;
            this.nickNameValidationText = this.$t(response.data.data).toString();
        } else {
            this.nickNameExisted = false;
        }
        });
    }

    private checkExistFirstNameAndLastName() {
        const userProfileModel = new UserProfileModel();
        userProfileModel.firstName = this.userModel.firstName;
        userProfileModel.lastName = this.userModel.lastName;
        
        
        userService.checkExistFirstNameAndLastName(userProfileModel).then((response: any) => {
        if (response.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
            this.firstlastExisted = true;
            this.firstNameValidationText = this.$t(response.data.data).toString();
            this.lastNameValidationText = this.$t(response.data.data).toString();
        } else {
            this.firstlastExisted = false;
        }
        });
    }

    private created() {
        if(!(this.$route.query.ref === null || this.$route.query.ref === undefined)) {
            Cookies.set("register_user_ref", this.$route.query.ref)
        }
    }

    private onComplete() {
        this.success = false;
        this.checkYearOldValid();
        this.checkRulesValid();
        if(!(this.checkYearOld && this.checkRules)) {
            alert(this.$t('form_user.validation.checkRules').toString());
            return;
        }
        if(this.checkValidateComplete()) {
            this.isLoading = true;
            userService
            .registerUserByRef(this.userModel)
            .then((response) => {
                if (response.data.code === ResponseCommon.CODE_SUCCESS) {
                    this.success = true;
                }
            })
            .finally(() => (this.isLoading = false));
        }
    }

    private checkValidateComplete() {
        this.blurInput('nickname'); this.checkExistNickName();
        this.blurInput('username'), this.checkExistUserName();
        this.blurInput('email'); this.checkExistEmail();
        this.blurInput('emailconfirm');
        this.blurInput('password');
        this.blurInput('passwordconfirm');
        this.blurInput('mobileNumber'); this.checkExistMobilePhone();
        this.blurInput('firstName');
        this.blurInput('lastName');
        this.blurInput('address');
        this.blurInput('dateOfBirth');
        return (this.nickNameValidation() === undefined ? false : this.nickNameValidation())
            && (this.userNameValidation() === undefined ? false : this.userNameValidation())
            && (this.emailValidation() === undefined ? false : this.emailValidation())
            && (this.emailConfirmValid() === undefined ? false : this.emailConfirmValid())
            && (this.passwordValidation() === undefined ? false : this.passwordValidation())
            && (this.passwordConfirmValid() === undefined ? false : this.passwordConfirmValid())
            && (this.mobileNumberValidation() === undefined ? false : this.mobileNumberValidation())
            && (this.firstNameValidation() === undefined ? false : this.firstNameValidation())
            && (this.lastNameValidation() === undefined ? false : this.lastNameValidation())
            && (this.addressValidation() === undefined ? false : this.addressValidation())
            && (this.dateOfBirthValidation() === undefined ? false : this.dateOfBirthValidation());
    }

    private openPrivacyPolicyModal() {
        this.isPrivacyPolicyModal = true;
    }

    private openCookiePolicyModal() {
        this.isCookiePolicyModal = true;
    }

    private openCodeOfConductModal() {
        this.isCodeOfConductModal = true;
    }

    private openTermAndConditionModal() {
        this.isTermAndConditionModal = true;
    }

    private userNameValidation() {
        if (this.clearUserNameError) {
        return;
        }
        if (!(this.userModel.userName.length > 0)) {
        this.userNameValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (!this.regexUserName.test(this.userModel.userName)) {
        this.userNameValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
        return false;
        } else if (!(this.userModel.userName.length <= 12)) {
        this.userNameValidationText = this.$t('view.registermember.valid.usernamemax').toString();
        return false;
        } else if (this.userNameExisted) {
        return false;
        }
        return true;
    }

    private nickNameValidation() {
        if (this.clearNickNameError) {
        return;
        }
        if (!(this.userModel.nickName.length > 0)) {
        this.nickNameValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (!this.regexNickNamePassword.test(this.userModel.nickName)) {
        this.nickNameValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
        return false;
        } else if (!(this.userModel.nickName.length <= 12)) {
        this.nickNameValidationText = this.$t('view.registermember.valid.usernamemax').toString();
        return false;
        } else if (this.nickNameExisted) {
        return false;
        }
        return true;
    }

    private emailValidation() {
        const regexpEmail = /^[a-zA-Z0-9\-!#$%&`*+/=?^{|}~._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/gm;
        if (this.clearEmailError) {
        return;
        }
        if (!(this.userModel.email.length > 0)) {
        this.emailValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (!regexpEmail.test(this.userModel.email)) {
        this.emailValidationText = this.$t('view.registermember.valid.email').toString();
        return false;
        } else if (this.emailExisted) {
        return false;
        }
        return true;
    }

    private emailConfirmValid() {
        if (this.clearEmailConfirmError) {
        return;
        }
        if (!(this.emailConfirm.length > 0)) {
        this.emailConfirmValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (this.emailConfirm !== this.userModel.email) {
        this.emailConfirmValidationText = this.$t('view.registermember.valid.emailconfirm').toString();
        return false;
        }
        return true;
    }

    private passwordValidation() {
        if (this.clearPasswordError) {
        return;
        }
        if (!(this.userModel.passWord.length > 0)) {
        this.passwordValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (!this.regexNickNamePassword.test(this.userModel.passWord)) {
        this.passwordValidationText = this.$t('view.registermember.valid.usernamecharacter').toString();
        return false;
        } else if (!(this.userModel.passWord.length > 5)) {
        this.passwordValidationText = this.$t('view.registermember.valid.passwordmin').toString();
        return false;
        }
        return true;
    }

    private passwordConfirmValid() {
        if (this.clearPasswordConfirmError) {
        return;
        }
        if (!(this.passwordConfirm.length > 0)) {
        this.passwordConfirmValidationText = this.$t('validation.input.required').toString();
        return false;
        } else if (this.passwordConfirm !== this.userModel.passWord) {
        this.passwordConfirmValidationText = this.$t('view.registermember.valid.passwordconfirm').toString();
        return false;
        }
        return true;
    }

    private checkYearOldValid() {
        if (!this.checkYearOld) {
            this.checkYearOldText = this.$t('view.registermember.valid.checkyearold').toString();
            this.errorYearOld = false;
        }else {
            this.checkYearOldText = '';
            this.errorYearOld = true;
        }
        
    }

    private checkRulesValid() {
        if (!this.checkRules) {
            this.checkRulesText = this.$t('view.registermember.valid.checkyearold').toString();
            this.errorRules = false;
        } else {
            this.checkRulesText = '';
            this.errorRules = true;
        }
        
    }

    // private validateFirstStep() {
    //     this.clearUserNameError = false;
    //     this.clearNickNameError = false;
    //     this.clearEmailError = false;
    //     this.clearEmailConfirmError = false;
    //     this.clearPasswordError = false;
    //     this.clearPasswordConfirmError = false;
    //     if (
    //     this.userNameValidation() &&
    //     this.nickNameValidation() &&
    //     this.emailValidation() &&
    //     this.emailConfirmValid() &&
    //     this.passwordValidation() &&
    //     this.passwordConfirmValid()
    //     ) {
    //     return true;
    //     }
    //     return false;
    // }
}
