import { Response } from '@/models';
import { ManageKyc } from '@/models/ManageKyc';
import { AxiosPromise } from 'axios';
import Vue from 'vue';

class ManageKycService extends Vue {

    public registerKyc(data: any): AxiosPromise<Response<ManageKyc>> {
        return Vue.axios.post<Response<ManageKyc>>(
            process.env.VUE_APP_API_URL + '/webapi/manage-kyc/register-kyc',
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            });
    }

    public getCreatedKyc(): AxiosPromise<Response<any>> {
        return this.axios.get<Response<any>>(process.env.VUE_APP_API_URL + '/webapi/manage-kyc/created-kyc');
    }

    public getKYCInformation(): AxiosPromise<Response<any>> {
        return this.axios.get<Response<any>>(process.env.VUE_APP_API_URL + '/webapi/manage-kyc/get-kyc-information');
    }
}

export const manageKycService = new ManageKycService();