























































import { Vue, Component } from 'vue-property-decorator';
import { loginService, userService } from '@/services';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {
  },
})
export default class Login extends Vue {
  private password = '';
  private username = '';
  private isLoading = false;
  private isError = false;
  private isServerError = false;
  private isUnauthorized = false;
  private isPasswordError = false;

  private created() {
    this.$store.dispatch('setUser', {});
    if (this.axios.defaults.headers.common.Authorization != null) {
      this.$router.push('/');
    }
  }

  private onClickLoginButton() {
    // check site from URL
    if (this.$route.params.site) {
    this.login(this.$route.params.site);
    } else {
    this.login('');
    }
  }

  private login(site: string) {
    this.resetError();
    const loginData = {
      username: this.username,
      password: this.password,
    };
    this.isLoading = true;
    // If site exits go to site
    if (site) {
      loginService
        .login(loginData)
        .then((isSuccess) => {
          if (isSuccess) {
              this.goToFlatform(site);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.isUnauthorized = true;
          } else if (error.response && error.response.status === 500) {
            this.isServerError = true;
          } else {
            this.isError = true;
          }
      }).finally(() => (this.isLoading = false));
    } else {
      // If site not exits, open a new tab and process login
      loginService
        .login(loginData)
        .then((isSuccess) => {
          if (isSuccess) {
            const clientId = this.$route.query.client_id;
            const responseType = this.$route.query.response_type;
            const redirectUri = this.$route.query.redirect_uri;
            const scope = this.$route.query.scope;
            const state = this.$route.query.state;
            if (clientId != undefined) {
              const path = 'oauth/authorize?';
              const queryParams = [ 'client_id=' + clientId,
                      'redirect_uri=' + redirectUri,
                      'scope=' + scope,
                      'response_type=' + responseType];
              const query = queryParams.join('&');
              const url = process.env.VUE_APP_API_URL + '/' + path + query;
              this.axios.get(url).then((response: any) => {
              window.location.href = response.request.responseURL;
              }).catch(error => {
                console.log(error);
              });
            }
            if(this.$route.params.redirect) {
              if(this.$route.params.machineId && this.$route.params.modelId) {
                this.$router.push({
                  name: 'machine',
                  params: { 
                    modelId : this.$route.params.modelId,
                    machineId: this.$route.params.machineId
                  },
                });
              } else {
                this.$router.push(this.$route.params.redirect);
              }
            } else {
                this.$router.push('/');
            }
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.isUnauthorized = true;
          } else if (error.response && error.response.status === 500) {
            this.isServerError = true;
          } else {
            this.isError = true;
          }
        }).finally(() => (this.isLoading = false));
    }
  }

  get isValid() {
    if (this.username !== '' && this.password !== '') {
      return false;
    }
    return true;
  }

  private checkPassword(password: string): boolean {
    return /^[a-zA-Z0-9!@#$%^&*]{8,64}$/g.test(password);
  }

  private resetError() {
    this.isError = false;
    this.isServerError = false;
    this.isUnauthorized = false;
    this.isPasswordError = false;
  }

  // Go to site if Authen true
  private goToFlatform(param: string) {
    if (!param) {
      return;
    }
    if (this.axios.prototype.hasUserInfo()) {
      userService.tokenPlatform(param).then((response) => {
        if (response.data.code === ResponseCommon.CODE_SUCCESS && response.data.data) {
          window.location.assign(response.data.data);
        }
      });
    } else {
      userService.goraMenu(param).then((response) => {
        if (response.data.code === ResponseCommon.CODE_SUCCESS && response.data.data) {
          window.location.assign(response.data.data);
        }
      });
    }
  }
}
