import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Content from '../views/layouts/Content.vue';
import Pachislot from '../views/layouts/Pachislot.vue';
import SelectFloor from '../views/layouts/SelectFloor.vue';
import LoginView from '../views/user/LoginView.vue';
import axios from 'axios';
import UserProfilePage from '@/views/user/UserProfilePage.vue';
import MachinePage from '@/views/MachinePage.vue';
import VerifyUserPage from '@/views/register/VerifyUserPage.vue';
import CallbackOauth2 from '@/views/oauth/CallbackOauth2.vue';
import ProgressOauth from '@/views/oauth/ProgressOauth.vue';
import TransactionMenuPage from '@/views/user/transaction/TransactionMenuPage.vue';
import HowToPlay from '../views/HowToPlay.vue';
import GameRules from '../views/GameRules.vue';
import TopPage from '../views/TopPage.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsOfUse from '../views/TermsOfUse.vue';
import AboutUs from '../views/AboutUs.vue';
import Login from '../views/user/Login.vue';
import Register from '../views/user/Register.vue';
import ContactUs from '../views/ContactUs.vue';
import AMLPolicy from '../views/AMLPolicy.vue';
import KYCPolicy from '../views/KYCPolicy.vue';
import ImgUpload from '../views/ImgUpload.vue';
import ManageKYC from '../views/user/ManageKYC.vue';
import PasswordResetPage from '@/views/user/PasswordResetPage.vue';
import DepositWithCreditCardPage from '@/views/user/transaction/deposit-with-credit-card/DepositWithCreditCardPage.vue';
import TransactionHistoryPage from '@/views/user/transaction/transaction-history/TransactionHistoryPage.vue';
import DepositWithWalletPage from '@/views/user/transaction/deposit-with-wallet/DepositWithWalletPage.vue';
import DepositPage from '@/views/user/transaction/deposit/DepositPage.vue';
import FreePlayPage from '@/views/FreePlayPage.vue';
import ResponsibleGamingPolicy from '@/views/ResponsibleGamingPolicy.vue'
import QandA from '@/views/QandA.vue';
import ForgotPassword from '@/views/user/ForgotPassword.vue';
import ReceiveCreditCard from '@/views/user/transaction/deposit-with-credit-card/ReceiveCreditCard.vue';
import WithdrawalPage from '@/views/user/transaction/withdrawal/WithdrawalPage.vue';
import PlayHistoryPage from '@/views/user/PlayHistoryPage.vue';
import ShiftHasEnded from '@/views/ShiftHasEnded.vue';
import moment from 'moment';
import { commonService } from '../services';
import ResponseCommon from '@/commons/ResponseCommon';
import DepositWithCrypto from '@/views/user/transaction/deposit-with-crypto/DepositWithCrypto.vue';
import DepositWithCryptoConfirm from '@/views/user/transaction/deposit-with-crypto/DepositWithCryptoConfirm.vue';

Vue.use(VueRouter);

  const routes: Array<RouteConfig> = [
  {
    path: '/pachinko',
    name: 'home',
    component: Content,
  },
  {
    path: '/pachislot',
    name: 'home',
    component: Pachislot,
  },
  {
    path: '/select-floor',
    name: 'home',
    component: SelectFloor,
    meta: { checkSession: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: ContactUs,
  },
  {
    path: '/callbackOauth2',
    name: 'callbackOauth2',
    component: CallbackOauth2,
  },
  {
    path: '/progressOauth',
    name: 'progressOauth',
    component: ProgressOauth,
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: TransactionMenuPage,
    meta: { requiresAuth: true, kyc: true },
  },
  {
    path: '/deposit-with-credit-card',
    name: 'deposit-with-credit-card',
    component: DepositWithCreditCardPage,
    meta: { requiresAuth: true, kyc: true, checkLock: true },
  },
  {
    path: '/deposit-with-credit-card/receive-credit-card',
    name: 'deposit-with-credit-card.receive-credit-card',
    component: ReceiveCreditCard,
    meta: { requiresAuth: true, kyc: true, checkLock: true },
  },
  {
    path: '/machine/:modelId',
    name: 'machine',
    component: MachinePage,
    props: true,
    meta: { checkSession: true }
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    component: VerifyUserPage,
  },
  {
    path : '/how-to-play',
    name : 'HowToPlay',
    component :HowToPlay
  },
  {
    path : '/game-rules',
    name : 'GameRules',
    component :GameRules
  },
  {
    path : '/',
    name : 'TopPage',
    component :TopPage
  },
  {
    path : '/terms-of-use',
    name : 'TermsOfUse',
    component :TermsOfUse
  },
  {
    path : '/privacy-policy',
    name : 'PrivacyPolicy',
    component :PrivacyPolicy
  },
  {
    path : '/about-us',
    name : 'AboutUs',
    component :AboutUs
  },
  {
    path : '/aml-policy',
    name : 'AMLPolicy',
    component :AMLPolicy
  },
  {
    path : '/kyc-policy',
    name : 'KYCPolicy',
    component :KYCPolicy
  },
  {
    path: '/img-upload',
    name: 'ImageUpload',
    component: ImgUpload,
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordResetPage,
  },
  {
    path: '/transaction-history',
    name: 'transaction-history',
    component: TransactionHistoryPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/deposit-with-wallet',
    name: 'deposit-with-wallet',
    component: DepositWithWalletPage,
    meta: { requiresAuth: true, kyc: true, checkLock: true },
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: DepositPage,
  },
  {
    path: '/free-play',
    name: 'free-play',
    component: FreePlayPage,
  },
  {
    path: '/manage-KYC',
    name: 'ManageKYC',
    component: ManageKYC,
    meta: { requiresAuth: true, kycNotDone: true },
  },
  {
    path: '/responsible-gaming-policy',
    name: 'responsible-gaming-policy',
    component: ResponsibleGamingPolicy,
  },
  {
    path: '/q-and-a',
    name: 'q-and-a',
    component: QandA,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: WithdrawalPage,
    meta: { requiresAuth: true, checkLock: true },
  },
  {
    path: '/play-history',
    name: 'play-history',
    component: PlayHistoryPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/shift-has-ended',
    name: 'shift-has-ended',
    component: ShiftHasEnded,
  },
  {
    path: '/deposit-with-crypto',
    name: 'deposit-with-crypto',
    component: DepositWithCrypto,
    meta: { requiresAuth: true, kyc: true, checkLock: true }
  },
  {
    path: '/deposit-with-crypto/confirm',
    name: 'deposit-with-crypto.confirm',
    component: DepositWithCryptoConfirm,
    meta: { requiresAuth: true, kyc: true, checkLock: true }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    else{
      return { x: 0, y: 0 }
    }
  }
});


const DEFAULT_TITLE = 'Premier';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwttoken') == null) {
      delete axios.defaults.headers.common.Authorization;
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      const lockAccount = (JSON.parse(localStorage.getItem('lockAccount') as any) as number);
      if(to.matched.some((record) => record.meta.checkLock)) {

        if(lockAccount === 1) { // Account lock
          next({ path: '/' });
        }
      }

      const kyc = JSON.parse(localStorage.getItem('kyc') as any);
      if(to.matched.some((record) => record.meta.kyc)) {
        if(!kyc) {
          next({ path: '/' });
        }

        if(kyc.points) {
          if(kyc.information && kyc.information.kycStatus === 'DONE') {
            next();
          } else {
            next({ path: '/' });
          }
        } else {
          next();
        }
      }

      if(to.matched.some((record) => record.meta.kycNotDone)) {
        if(!kyc) {
          next({ path: '/' });
        }

        if(!kyc.information || kyc.information && kyc.information.kycStatus !== 'DONE' && kyc.information.documentStatus === 'REQUEST_SENT') {
          next();
        } else {
          next({ path: '/' });
        }
      }

      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwttoken') == null) {
      next();
    } else {
      next({ name: 'userboard' });
    }
  } else {
    //Site behavior
    //1. During business hours (8:00-17:59, 20:00-5:59 the next day): All functions are available
    //2. Outside business hours (6:00-7:59, 18:00-19:59): Only user registration, login, deposits/withdrawals, and free play are possible (paid play is not possible)
    if(to.matched.some((record) => record.meta.checkSession)) {
      let timeJP = moment.utc().tz('Asia/Tokyo').format('HH:mm');
      await commonService.getCurrentTimeOfTheServer()
        .then((res: any) => {
          if(res.data.code === ResponseCommon.CODE_SUCCESS){
            timeJP = moment.utc(res.data.data).tz('Asia/Tokyo').format('HH:mm');
          }
        })
      const condition1 = timeJP >= moment('18:00', 'HH:mm').format('HH:mm') && timeJP <= moment('19:59', 'HH:mm').format('HH:mm');
      const condition2 = timeJP >= moment('06:00', 'HH:mm').format('HH:mm') && timeJP <= moment('07:59', 'HH:mm').format('HH:mm');
      if(condition1 || condition2) {
        next({ path: '/shift-has-ended' });
      } else {
        next();
      }
    }

    next();
  }
});

export default router;
