import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessage from './en.json';
import jaMessage from './ja.json';
import cnMessage from './cn.json';
import store from '../store';

Vue.use(VueI18n);

const messages = {
  en: enMessage,
  ja: jaMessage,
  cn: cnMessage,
};

const i18n = new VueI18n({
  locale: store.state.storedLang, // set locale
  messages,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
});

export default i18n;
