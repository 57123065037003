export class ContactModel {
  public id?: number;
  public email = '';
  public subject = '';
  public message = '';
  public name = '';
  public phone = '';
  constructor(init?: Partial<ContactModel>) {
    Object.assign(this, init);
  }
}
