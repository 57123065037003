import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

const URL_ENV = process.env.VUE_APP_API_URL + '/webapi/withdrawal';

class WithdrawalService extends Vue {

  public withdrawalApplication(data: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV, data);
  }
}
export const withdrawalService = new WithdrawalService();
