import Vue from 'vue';

const URI_GET_LIST_MACHINE_BY_MODEL_ID = process.env.VUE_APP_API_URL + '/webapi/machine/getListMachineByModelId';
class MachineService extends Vue {

  public getListMachinesByModelId(modelId: number) {
    const jwttoken = localStorage.getItem('jwttoken');
    return this.axios.get(URI_GET_LIST_MACHINE_BY_MODEL_ID + '/' + modelId, 
    { headers: {"Authorization" : `Bearer ${jwttoken}`} });
  }
}
export const machineService = new MachineService();

