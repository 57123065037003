import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response, User } from '@/models';
import { UserProfileModel } from '@/models/UserProfileModel';

const URI_CHECK_USER_LOGIN = process.env.VUE_APP_API_URL + '/webapi/user/checkUserIsAuth';
const URI_RESET_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/resetPassword';
const URI_GET_CURRENT_USER_NAME = process.env.VUE_APP_API_URL + '/webapi/user/getCurrentUserName';
const URI_REGISTER_USER = process.env.VUE_APP_API_URL + '/webapi/user/register-user';
const URI_FORGET_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/forgetPassword';
const URI_ACTIVE_USER = process.env.VUE_APP_API_URL + '/webapi/user/active';
const URI_GET_USER_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/get-user-profile';
const URI_UPDATE_USER_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/updateUserProfile';
const URI_GEN_TOKEN = process.env.VUE_APP_API_URL + '/webapi/user/token-platform';
const URI_GORA_MENU = process.env.VUE_APP_API_URL + '/webapi/user/gora-menu';
const URI_CHECK_EXITS_EMAIL = process.env.VUE_APP_API_URL + '/webapi/user/check-exist/email';
const URI_CHECK_EXITS_USERNAME = process.env.VUE_APP_API_URL + '/webapi/user/check-exist/username';
const URI_CHECK_EXITS_MOBILE_PHONE = process.env.VUE_APP_API_URL + '/webapi/user/check-exist/mobile-phone';
const URI_CHECK_EXITS_NICKNAME = process.env.VUE_APP_API_URL + '/webapi/user/check-exist/nickname';
const URI_CHECK_EXITS_FIRSTNAME_LASTNAME = process.env.VUE_APP_API_URL + '/webapi/user/check-exist/firstname-lastname';
const URI_UPDATE_NICKNAME = process.env.VUE_APP_API_URL + '/webapi/user/update-nickname';
const URI_UPDATE_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/update-password';
const URI_CHANGE_LANGUAGE = process.env.VUE_APP_API_URL + '/webapi/user/change-language';
const URI_UPDATE_EMAIL = process.env.VUE_APP_API_URL + '/webapi/user/update-email';
const URI_UPDATE_MOBILE_NUMBER = process.env.VUE_APP_API_URL + '/webapi/user/update-mobile-number';
const URI_PORGOT_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/forgot-password';
const URI_CONFIRM_RESET_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/reset-password';

class UserService extends Vue {

    public getCurrentUserName() {
        return this.axios.post(URI_GET_CURRENT_USER_NAME);
    }

    public registerUser(data: User): AxiosPromise<Response<User>> {
        return this.axios.post<Response<User>>(URI_REGISTER_USER, data);
    }

    public registerUserByRef(data: User): AxiosPromise<Response<User>> {
        return this.axios.post<Response<User>>(URI_REGISTER_USER, data, { withCredentials: true });
    }

    public activeUser(token: string): AxiosPromise<Response<any>> {
        return this.axios.get<Response<any>>(URI_ACTIVE_USER + '/' + token);
    }

    public getUserProfile(): AxiosPromise<Response<any>> {
        return this.axios.get<Response<any>>(URI_GET_USER_PROFILE);
    }

    public updateUserProfile(data: User): AxiosPromise<Response<User>> {
        return this.axios.post<Response<User>>(URI_UPDATE_USER_PROFILE, data);
    }

    public forgetPassword(email: string) {
        return this.axios.get(URI_FORGET_PASSWORD + '/' + email);
    }

    public resetPassword(newPassword: string, token: string) {
        return this.axios.post(URI_RESET_PASSWORD, { newPassword, token });
    }

    public checkUserIsAuth() {
        return this.axios.post(URI_CHECK_USER_LOGIN);
    }

    public tokenPlatform(site: string) {
        return this.axios.get(URI_GEN_TOKEN + '/' + site);
    }

    // public tokenAllPlatform() {
    //     return this.axios.get(URI_GEN_TOKEN_ALL);
    // }

    public goraMenu(site: string) {
        return this.axios.get(URI_GORA_MENU + '/' + site);
    }

    public checkExistEmail(data: UserProfileModel): AxiosPromise<UserProfileModel> {
        return this.axios.post<UserProfileModel>(URI_CHECK_EXITS_EMAIL, data);
    }

    public checkExistUserName(data: UserProfileModel): AxiosPromise<UserProfileModel> {
        return this.axios.post<UserProfileModel>(URI_CHECK_EXITS_USERNAME, data);
    }

    public checkExistMobilePhone(data: UserProfileModel): AxiosPromise<UserProfileModel> {
        return this.axios.post<UserProfileModel>(URI_CHECK_EXITS_MOBILE_PHONE, data);
    }

    public checkExistNickName(data: UserProfileModel): AxiosPromise<UserProfileModel> {
        return this.axios.post<UserProfileModel>(URI_CHECK_EXITS_NICKNAME, data);
    }

    public updateNickName(data: any): AxiosPromise<Response<User>> {
        return this.axios.put<Response<User>>(URI_UPDATE_NICKNAME, data);
    }

    public checkExistFirstNameAndLastName(data: UserProfileModel): AxiosPromise<UserProfileModel> {
        return this.axios.post<UserProfileModel>(URI_CHECK_EXITS_FIRSTNAME_LASTNAME, data);
    }

    public updatePassword(data: any): AxiosPromise<Response<User>> {
        return this.axios.put<Response<User>>(URI_UPDATE_PASSWORD, data);
    }
    
    public changeLanguage(data: any): AxiosPromise<Response<any>> {
        return this.axios.post<Response<any>>(URI_CHANGE_LANGUAGE, data);
    }

    public updateEmail(data: any): AxiosPromise<Response<User>> {
        return this.axios.put<Response<User>>(URI_UPDATE_EMAIL, data);
    }

    public updateMobileNumber(data: any): AxiosPromise<Response<User>> {
        return this.axios.put<Response<User>>(URI_UPDATE_MOBILE_NUMBER, data);
    }

    public forgotPassword(data: any): AxiosPromise<Response<any>> {
        return this.axios.post<Response<any>>(URI_PORGOT_PASSWORD, data);
    }

    public confirmResetPassword(data: any): AxiosPromise<Response<any>> {
        return this.axios.post<Response<any>>(URI_CONFIRM_RESET_PASSWORD, data);
    }
}

export const userService = new UserService();

