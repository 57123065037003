import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

class TransactionHistoryService extends Vue {

  public getAllByUserId(data: any): AxiosPromise<Response<any>> {
    return this.axios.get(process.env.VUE_APP_API_URL + '/webapi/transaction-history?' + data);
  }
  
  public isKyc() {
    return Vue.axios.get(process.env.VUE_APP_API_URL + '/webapi/transaction-history/is-kyc');
  }
}

export const transactionHistoryService = new TransactionHistoryService();
