






import { Component, Vue } from 'vue-property-decorator';
import ResponseCommon from '@/commons/ResponseCommon';

@Component({
  components: {
  },
})

export default class CallbackOauth2 extends Vue {
  

    private mounted() {
      const redirectUri = this.$route.query.redirect_uri;
      if (redirectUri != undefined) {
        window.location.href = redirectUri + '';
      }
    }
}
