import Vue from 'vue';
import mainApp from '@/main';
import {ResponseCodeEnum} from '@/models/ResponseCodeEnum';

class ToastService extends Vue {

  public success(messenger: string) {
    Vue.toasted.success(messenger);
  }

  public error(code: number, messenger?: string) {
    let showMessage = '';
    if (code === ResponseCodeEnum.CODE_PERMISSION) {
      showMessage = '全く許可しません';
    } else if (code === ResponseCodeEnum.CODE_NOT_FOUND) {
      // showMessage = '記録が見当たりませんでした';
    } else if (code === ResponseCodeEnum.CODE_ALREADY_EXIST) {
      // showMessage = '記録は既に存在します';
    } else if (code === ResponseCodeEnum.CODE_EXPIRED) {
      showMessage = 'もう一度ログインしてください';
    } else if (code === ResponseCodeEnum.CODE_INTERNAL_ERROR) {
      showMessage = '内部サーバーエラー' + (messenger ? ' - ' + messenger : '');
    } else if (code === ResponseCodeEnum.CODE_BUSINESS) {
      showMessage = (messenger ? messenger : '');
    }
    mainApp.$toasted.error(showMessage);
  }

  public errorMessage(messenger: string, args?: string[]) {
    const msg = messenger + (args ? ' ' + [...args].join(', ').toString() : '');
    mainApp.$toasted.error(msg);
  }

  public successMessage(messenger: string, args?: string[]) {
    const msg = messenger + (args ? ' ' + [...args].join(', ').toString() : '');
    mainApp.$toasted.success(msg);
  }

  public infoMessage(messenger: string, args?: string[]) {
    const msg = messenger + (args ? ' ' + [...args].join(', ').toString() : '');
    mainApp.$toasted.info(msg);
  }

}

export const toastService = new ToastService();
