














































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { userService } from '@/services';
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';
import { withdrawalService } from '@/services';

@Component({
  components: {}
})
export default class WithdrawalPage extends Vue {
  private step = 1;
  private fee = 0.05;
  private balanceUser = 0;
  private currency = '';
  private amountWithdrawal = 0;
  private paymentFee = 0;
  private totalWithdrawal = 0;
  private withdrawalWalletAddress = '';
  private error = false;
  private errorMessage = '';
  private regexBTCAddress = /^(bc1|[13])[a-km-zA-HJ-NP-Z1-9]{25,34}$/;
  private regexETHAddress = /^(0x)?[0-9a-fA-F]{40}$/;
  private regexUSDTAddress = /^T[a-zA-Z0-9]{33}$/;

  @Watch('amountWithdrawal')
  private changeAmountWithdrawal() {
    if(!(/^\d*\.?\d{0,2}$/.exec(this.amountWithdrawal.toString()))) {
      this.amountWithdrawal = Number(this.amountWithdrawal.toString().slice(0, -1));
    }
    this.changeAmount();
  }

  private created() {
    userService
      .getUserProfile()
      .then((res: any) => {
        if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
          this.balanceUser = res.data.data.balance;
        }
      })
  }

  private confirmWithdrawal() {
    this.error = true;
    this.errorMessage = '';

    if(!this.withdrawalWalletAddress) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.required_withdrawal_wallet_address' + '_' + this.currency;
      return;
    }

    if(this.currency === 'BTC' && !this.regexBTCAddress.test(this.withdrawalWalletAddress)) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.required_withdrawal_wallet_address' + '_' + this.currency;
      return;
    }

    if(this.currency === 'ETH' && !this.regexETHAddress.test(this.withdrawalWalletAddress)) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.required_withdrawal_wallet_address' + '_' + this.currency;
      return;
    }

    if(this.currency === 'USDT' && !this.regexUSDTAddress.test(this.withdrawalWalletAddress)) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.required_withdrawal_wallet_address' + '_' + this.currency;
      return;
    }

    (this.$refs.btn_confirm_withdrawal as any).disabled = true;
    withdrawalService.withdrawalApplication({
      amountWithdrawal: this.amountWithdrawal,
      paymentFee: this.paymentFee,
      totalWithdrawal: this.totalWithdrawal,
      withdrawalWalletAddress: this.withdrawalWalletAddress,
      currency: this.currency
    }).then((res: any) => {
      if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
        this.error = false;
        this.errorMessage = '';
        this.step = 3;
        (this.$refs.btn_confirm_withdrawal as any).disabled = false;
      }
    }).finally(() => (this.$refs.btn_confirm_withdrawal as any).disabled = false)
  }

  private formatPrice(value: any) {
    const val = (value / 1).toFixed(2).replace(',', '.');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private selectCurrencyToWithdrawal(currency: string) {
    this.error = false;
    this.errorMessage = '';
    if(this.totalWithdrawal > this.balanceUser) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.greater_than_the_amount_available';
      return;
    }
    if(this.amountWithdrawal < 10) {
      this.error = true;
      this.errorMessage = 'withdrawal.error_message.less_than_the_specified_amount';
      return;
    }
    this.currency = currency;
    this.step = 2;
  }

  private changeAmount() {
    this.paymentFee = Number((this.amountWithdrawal * this.fee).toFixed(2));
    this.totalWithdrawal = Number((Number(this.paymentFee) + Number(this.amountWithdrawal)).toFixed(2));
  }

  private goToPage(path: string) {
    this.$router.push({
      path: path
    });
  }
}
