











































































































import { Vue, Component } from 'vue-property-decorator';
import { contactService } from '@/services'
import { ContactModel } from '@/models/ContactModel';
import { ResponseCodeEnum } from '@/models/ResponseCodeEnum';

@Component({
  components: {
  },
})
export default class ContactUs extends Vue {
    private errorMsg = '';
    private error = false;
    private contact: ContactModel = new ContactModel();
    private checkRule = false;
    private step = 'init';

    private created() {
        this.contact.message = this.$route.query.message ? this.$route.query.message + '' : ''
    }

    private submit() {
        this.error = false;
        if(!this.contact.name || !this.contact.email || !this.contact.subject || !this.contact.message) {
            this.error = true;
            this.errorMsg= 'contact_us.error_msg.required_all_fields';
            return;
        }
        if(!(/^[a-zA-Z0-9\-!#$%&`*+/=?^{|}~._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/gm.test(this.contact.email))) {
            this.error = true;
            this.errorMsg= 'contact_us.error_msg.regex_email';
            return;
        }
        if(!this.checkRule) {
            this.error = true;
            this.errorMsg= 'contact_us.error_msg.check_rule';
            return;
        }
        contactService.sendContact(this.contact)
            .then((res: any) => {
                if(res.data && res.data.code === ResponseCodeEnum.CODE_SUCCESS) {
                    this.step = 'success';
                } else {
                    this.error = true;
                    this.errorMsg = res.data.data;
                }
            })
    }
}
