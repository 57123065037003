










































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class BetFooter extends Vue {
  private isContactModal = false;

  private openContactDialog() {
    this.isContactModal = true;
  }

  private closeContactModal() {
    this.isContactModal = false;
  }
}
