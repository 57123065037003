



















































import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ReceiveCrypto extends Vue {

  private getAddress(coinInput: string, protocolInput: string) {
    this.$router.push({
      path: '/deposit-with-crypto/confirm',
      query: {
        coin: coinInput,
        protocol: protocolInput
      }
    })
  }
}
