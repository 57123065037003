






















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import MachineItem from '@/components/MachineView.vue';
import { machineService, modelService, machineChannelService, userCheatService, commonService } from '@/services';
import { Machine, Model } from '@/models';
import ResponseCommon from '@/commons/ResponseCommon';
import moment from 'moment';

@Component({
  components: {
    MachineItem,
  },
})
export default class MachinePage extends Vue {
  @Prop() private modelId!: number;
  // private machines: Machine[] = [];
  private machinesPC1: Machine[] = [];
  private machinesPC2: Machine[] = [];

  private machinesSP1: Machine[] = [];
  private machinesSP2: Machine[] = [];
  private machinesSP3: Machine[] = [];
  private machinesSP4: Machine[] = [];

  private intervalid = 0;
  private model: Model = new Model();
  private imgHeader = '';
  private imgHeaderShow = false;
  private listGoldenBallet: string[] = ['1001', '1002', '1003', '1004', '1005', '1006'];
  private listFeverJoker: string[] = ['1101', '1102', '1103', '1104', '1105', '1106'];
  private listMyJuggy: string[] = ['3001', '3002', '3003', '3004', '3005', '3006'];
  private listChurasai: string[] = ['2001', '2002', '2003', '2004', '2005', '2006'];

  private gameAssetsName = 'feverjoker';

  public dataPopupGame = {
    machineId: "",
    modelId: "",
    channelId: "",
    count: "",
    total: 0,
    bigBonus: 0,
    numberslide: "",
    regularBonus: 0,
    arrTable: [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
    machineNo: ''
  };
  public history = [-1, -1, -1, -1, -1, -1, -1];
  public paramCallGame = {
    modelId: "",
    channelId: "",
    machineId: "",
    action: "",
  };
  public isActive = false;
  public machineGame: any;
  private isLogin?: boolean;

  private allMachine: any[] = [];

  private showModalBackToGame = false;
  private linkBackGame = '';

  private marginTopContainer = '0px'

  private changeSizeScreen() {
    if(window.matchMedia("(min-width: 767px)").matches) {
      this.marginTopContainer = '';
    }
    if(window.matchMedia("(max-width: 768px)").matches) {
      this.marginTopContainer = (document.getElementById("header-top") as any).clientHeight + 'px';
    }
  }

  private backToGame() {
    window.location.href = this.linkBackGame;
  }

  private async playGame() {
    if (this.isLogin) {
      await userCheatService.checkLock().then((responseUserCheat) => {
        if (responseUserCheat.data.code === ResponseCommon.CODE_SUCCESS) {
          alert(this.$t('validation.game.user_locked').toString());
        } else if (responseUserCheat.data.code === ResponseCommon.CODE_INVALID && this.machineGame.id && this.machineGame.modelId && this.machineGame.channelId) {
          modelService.showWarningPopup().then((response) => {
            if (response.data.code === ResponseCommon.CODE_SUCCESS) {
              this.showModalBackToGame = true;
              this.linkBackGame = response.data.data;
            } else if(response.data.code === ResponseCommon.CODE_NOT_FOUND) {
              machineChannelService.checkUsedMachine({
                machineId: this.machineGame.id,
                modelId: this.machineGame.modelId,
                channelId: this.machineGame.channelId,
              }).then((responseMachineChannel) => {
                if (responseMachineChannel.data.code === ResponseCommon.CODE_SUCCESS) {
                  // Set state machine is waiting when redirect to game
                  machineChannelService.changeMachineWaiting({
                    machineId: this.machineGame.id,
                    modelId: this.machineGame.modelId,
                    channelId: this.machineGame.channelId,
                  }).then((response) => {
                    if (response.data.code === ResponseCommon.CODE_SUCCESS && this.machineGame && this.machineGame.gameDetail) {
                      // Open game
                      window.location.href = this.machineGame.gameDetail.startLink
                        + '?model_id=' + this.machineGame.modelId
                        + '&channel_id=' + this.machineGame.channelId
                        + '&machine_id=' + this.machineGame.id;
                    }
                  });
                } else if (responseMachineChannel.data.code === ResponseCommon.CODE_ALREADY_EXIST) {
                  alert(this.$t('validation.game.used_machine').toString());
                }
              });
            }
          });
        }
      });
    } else {
      this.$router.push({
        name: 'login',
        params: {
          redirect: this.$route.fullPath,
          machineId: this.machineGame.id,
          modelId: this.modelId.toString()
        },
      });
    }
  }

  private openPopup() {
    this.isActive = true;
  }

  private closePopup() {
    this.isActive = false;
    this.dataPopupGame = {
      machineId: "",
      machineNo: '',
      modelId: "",
      channelId: "",
      count: "",
      total: 0,
      bigBonus: 0,
      numberslide: "",
      regularBonus: 0,
      arrTable: [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    }
    this.history = [-1, -1, -1, -1, -1, -1, -1];
  }

  private checkValidateTable(times: number, count: number) {
    if (times === 0 || count === -1) {
      return "";
    } else if (times === 1) {
      if (count !== -1) {
        return "lightGreen";
      } else {
        return "darkGreen";
      }
    } else {
      if (((times - 1) * 100) <= count) {
        return "lightGreen";
      } else {
        return "darkGreen";
      }
    }
  }

  private getDataPopupGame(machine: any) {
    console.log(machine)
  }

  public listenData(event: any) {
    this.machineGame = event;
    this.paramCallGame.machineId = event.id.toString();
    this.paramCallGame.modelId = event.modelId.toString();
    this.paramCallGame.channelId = event.channelId.toString();
    if (this.listFeverJoker.indexOf(this.paramCallGame.modelId) !== -1) {
      this.paramCallGame.action = "3";
    } else if (this.listGoldenBallet.indexOf(this.paramCallGame.modelId) !== -1) {
      this.paramCallGame.action = "2";
    } else if (this.listChurasai.indexOf(this.paramCallGame.modelId) !== -1) {
      this.paramCallGame.action = "1";
    } else {
      this.paramCallGame.action = "0";
    }
    this.isActive = event.activeclass;
    this.dataPopupGame.machineNo = event.machineNo
    this.callApiGame();
  }

  public callApiGame() {

    machineChannelService
      .callApiDataMachineGame(this.paramCallGame)
      .then((responseCallGame) => {
        this.dataPopupGame.count =
          responseCallGame.data.data.count == null
            ? 0
            : responseCallGame.data.data.count;
        this.dataPopupGame.total =
          responseCallGame.data.data.total == null
            ? 0
            : responseCallGame.data.data.total;
        this.dataPopupGame.bigBonus =
          responseCallGame.data.data.bigBonus == null
            ? 0
            : responseCallGame.data.data.bigBonus;
        this.dataPopupGame.regularBonus =
          responseCallGame.data.data.regularBonus == null
            ? 0
            : responseCallGame.data.data.regularBonus;
        this.history = JSON.parse(responseCallGame.data.data.history) ? JSON.parse(responseCallGame.data.data.history) : [-1, -1, -1, -1, -1, -1, -1];
      });
  }

  private async created() {
    window.addEventListener("resize", this.changeSizeScreen);

    await this.getMachinesByModel(this.modelId);
    await this.getModel();
    await this.getImgHeader();
    if (this.axios.prototype.hasUserInfo()) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    if(this.isLogin) {
      modelService.showWarningPopup().then((response) => {
        if (response.data.code === ResponseCommon.CODE_SUCCESS) {
          this.showModalBackToGame = true;
          this.linkBackGame = response.data.data;
        }
      });
    }

    // Reload state machine every 5 minutes in 1 time
    this.intervalid = setInterval(() => {
      this.getMachinesByModel(this.modelId);
    }, 300000);
  }

  private async mounted() {
    this.changeSizeScreen()

    this.checkShifts();
    this.timer = setInterval(() => {
      this.checkShifts();
    }, 10000);
  }

  private destroyed() {
    window.removeEventListener("resize", this.changeSizeScreen);
  }

  private getMachinesByModel(modelId: number) {
    machineService.getListMachinesByModelId(modelId).then((response) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        this.allMachine = response.data.data;

        if (this.$route.params.machineId) {
          const machine = response.data.data.find((e: any) => e.id === this.$route.params.machineId);
          if (machine) {
            this.listenData(machine);
            this.isActive = true;
          }
        }

        this.machinesPC1 = response.data.data.filter((x: any, index: number) => index < 10);
        this.machinesPC2 = response.data.data.filter((x: any, index: number) => index >= 10);

        this.machinesSP1 = response.data.data.filter((x: any, index: number) => index <= 4);
        this.machinesSP2 = response.data.data.filter((x: any, index: number) => index >= 5 && index <= 9);
        this.machinesSP3 = response.data.data.filter((x: any, index: number) => index >= 10 && index <= 14);
        this.machinesSP4 = response.data.data.filter((x: any, index: number) => index >= 15);
      }
    });
  }

  private async getModel() {
    await modelService.getByModelId(this.modelId).then((response) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        this.model = response.data.data;
        if (this.model.name === 'Fever joker') {
          this.gameAssetsName = 'feverjoker';
        }
        if (this.model.name === 'Golden Ballet') {
          this.gameAssetsName = 'goldenballet';
        }
        if (this.model.name === 'Churasai') {
          this.gameAssetsName = 'churasai';
        }
        if (this.model.name === 'My Juggy') {
          this.gameAssetsName = 'myjuggy';
        }
      }
    });
  }

  private async getImgHeader() {
    if (this.model.purchasePrice && this.model.cashingPrice) {
      this.imgHeaderShow = true;
      if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.009 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/1yen.png';
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.018 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/2yen.png';
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.036 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/4yen.png';

      } else if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.01 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/1yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.02 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/2yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.04 &&
        (this.listGoldenBallet.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/goldenballet/4yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.009 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/1yen.png';
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.018 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/2yen.png';
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.036 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/4yen.png';

      } else if (
        this.model.purchasePrice === 0.01 &&
        this.model.cashingPrice === 0.01 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/1yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.02 &&
        this.model.cashingPrice === 0.02 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/2yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.04 &&
        this.model.cashingPrice === 0.04 &&
        (this.listFeverJoker.indexOf(this.modelId.toString()) >= 0)
      ) {
        this.imgHeader = 'assets/images/icon_model/feverjoker/4yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.05 &&
        this.model.cashingPrice === 0.045
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/5yen.png';

      } else if (
        this.model.purchasePrice === 0.1 &&
        this.model.cashingPrice === 0.09
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/10yen.png';
      } else if (
        this.model.purchasePrice === 0.2 &&
        this.model.cashingPrice === 0.18
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/20yen.png';
      } else if (
        this.model.purchasePrice === 0.05 &&
        this.model.cashingPrice === 0.05
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/5yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.1 &&
        this.model.cashingPrice === 0.1
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/10yen_equivalent.png';
      } else if (
        this.model.purchasePrice === 0.2 &&
        this.model.cashingPrice === 0.2
      ) {
        this.imgHeader = 'assets/images/icon_model/churasai/20yen_equivalent.png';
      }
    }
  }

  private timer!: number;

  private async checkShifts() {
    let timeJP = moment.utc().tz('Asia/Tokyo').format('HH:mm');
    await commonService.getCurrentTimeOfTheServer()
      .then((res: any) => {
        if(res.data.code === ResponseCommon.CODE_SUCCESS){
          timeJP = moment.utc(res.data.data).tz('Asia/Tokyo').format('HH:mm');
        }
      })
    const condition1 = timeJP >= moment('18:00', 'HH:mm').format('HH:mm') && timeJP <= moment('19:59', 'HH:mm').format('HH:mm');
    const condition2 = timeJP >= moment('06:00', 'HH:mm').format('HH:mm') && timeJP <= moment('07:59', 'HH:mm').format('HH:mm');
    if(condition1 || condition2) {
      this.$router.replace({path: '/shift-has-ended'})
    }
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
