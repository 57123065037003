





















































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DepositPage extends Vue {
  private currencyType = 'USD';
  private amount = 0;
  private step = 1;
  
  private deposit() {
    this.step = 2;
  }

  private changeAmount() {
    this.step = 1;
  }

  private confirm() {
    this.step = 3;
  }
}
