





















import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { commonService } from '../services';
import ResponseCommon from '@/commons/ResponseCommon';

@Component
export default class ShiftHasEnded extends Vue {
  private timer!: number;
  private datetimeNow = moment.utc().tz('Asia/Tokyo').format('HH:mm');

  private getCurrentTimeJP() {
    commonService.getCurrentTimeOfTheServer()
      .then((res: any) => {
        if(res.data.code === ResponseCommon.CODE_SUCCESS){
          if(this.$store.state.storedLang === 'en') {
            this.datetimeNow = moment.utc(res.data.data).format('HH:mm');
          } else {
            this.datetimeNow = moment.utc(res.data.data).tz('Asia/Tokyo').format('HH:mm');
          }
        }
      })
  }

  async mounted() {
    this.getCurrentTimeJP();
    this.updateClock();
  }

  private updateClock() {
    this.timer = setInterval(() => {
      this.getCurrentTimeJP();
    }, 10000);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  private backToTop() {
    window.location.replace('/');
  }
}
