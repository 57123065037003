class Constants {
    public static readonly PACHINKO = 0;
    public static readonly SLOT = 1;

    public static readonly SHOW_TAG = 1;
    public static readonly SOCK_EVENT = 2;
    public static readonly PASSION_EVENT = 3;
    public static readonly EVEN_NUMBER_SETTING = 6;
    public static readonly HIGH_SETTING = 4;
}

export default Constants;
