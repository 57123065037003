export class UserInfoPaystage {
  public id?: number = undefined;
  public userId?: number = undefined;
  public addressLine1?: string = '';
  public addressLine2?: string = '';
  public city?: string = '';
  public country?: string = '';
  public email?: string = '';
  public firstName?: string = '';
  public lastName?: string = '';
  public mobile?: string = '';
  public state?: string = '';
  public zip?: string = '';
  public internationalDialing?: string = '';

  constructor(init?: Partial<UserInfoPaystage>) {
    Object.assign(this, init);
  }
}
