























import { Component, Vue } from 'vue-property-decorator';
import { userService } from '../../services';
import ResponseCommon from '../../commons/ResponseCommon';

@Component({
  name: 'VerifyUserPage',
  components: {},
})
export default class extends Vue {
  private token = '';
  private isSuccess = false;

  private created() {
    // Get param
    if (this.$route.params.token) {
      this.token = this.$route.params.token;
      this.verifyByEmail();
    }
  }

  private verifyByEmail() {
    userService.activeUser(this.token).then((response: any) => {
      if (response.data.code === ResponseCommon.CODE_SUCCESS) {
        this.isSuccess = true;
      } else {
        this.isSuccess = false;
      }
    });
  }

}
