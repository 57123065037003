import { UserStatusEnum } from './UserStatusEnum';

export class User {
  public id?: string;
  public userName = '';
  public passWord = '';
  public nickName = '';
  public balance?: number;
  public status?: UserStatusEnum;
  public email = '';
  public createdDate?: Date;
  public lastActive?: Date;
  public mobileNumber = '';
	public firstName = '';
	public lastName = '';
	public address = '';
	public birthday = '';
	public identityReferenceNumber = '';

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
