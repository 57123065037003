export class UserProfileModel {
  public userName?: string;
  public nickName?: string;
  public balance?: number;
  public email?: string;
  public firstName?: string;
  public lastName?: string;
  public mobileNumber?: string;
  constructor(init?: Partial<UserProfileModel>) {
    Object.assign(this, init);
  }
}
