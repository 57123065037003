import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

const URI_CHECK_LOCK = process.env.VUE_APP_API_URL + '/webapi/cheat/check-lock';
class UserCheatService extends Vue {

  public checkLock(): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URI_CHECK_LOCK);
  }
}
export const userCheatService = new UserCheatService();

