import Vue from 'vue';
import Vuex from 'vuex';
import mainApp from './main';

import createPersist from 'vuex-localstorage';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersist({
    namespace: 'goraku.root',
    initialState: {},
  })],
  state: {
    storedLang: 'ja',
    user: {},
    locale: 'ja',
    shopGame: '0',
  },
  mutations: {
    SET_LANG(state, storedLang) {
      mainApp.$i18n.locale = storedLang;
      state.storedLang = storedLang;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_SHOP_GAME(state, shopGame) {
      state.shopGame = shopGame;
    },
  },
  actions: {
    setLang({ commit }, storedLang) {
      commit('SET_LANG', storedLang);
    },
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setShopGame({ commit }, shopGame) {
      commit('SET_SHOP_GAME', shopGame);
    },
  },
});
