import Vue from 'vue';

const URI_DEPOSIT = process.env.VUE_APP_API_URL + '/webapi/transaction/deposit';
const URI_WITHDRAW = process.env.VUE_APP_API_URL + '/webapi/transaction/withdraw';
const URI_GOTO_PAYMENT_SITE = process.env.VUE_APP_API_URL + '/webapi/transaction/payment-menu';
class PaymentService extends Vue {

  public deposit(amount: number) {
    return this.axios.get(URI_DEPOSIT + '/' + amount);
  }

  public withdraw(amount: number) {
    return this.axios.get(URI_WITHDRAW + '/' + amount);
  }

  public goToPaymentSite() {
    return this.axios.get(URI_GOTO_PAYMENT_SITE);
  }

}
export const paymentService = new PaymentService();

