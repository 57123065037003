import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response } from '@/models';

const URL_ENV = process.env.VUE_APP_API_URL + '/webapi/user-info-paystage';

class UserInfoPaystage extends Vue {

  public getEmail(): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URL_ENV + '/get-email');
  }

  public getUserInfoPaystage(): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URL_ENV);
  }

  public createOrUpdateUserInfoPaystage(data: any): AxiosPromise<Response<any>> {
    return this.axios.post<Response<any>>(URL_ENV, data);
  }

  public getStateAndCityByZipCode(zipCode: string) {
    return this.axios.get(URL_ENV + '/get-state-and-city-by-zip-code/' + zipCode);
  }

  public getAllCountryCode(): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URL_ENV + '/get-all-country-code');
  }
}
export const userInfoPaystage = new UserInfoPaystage();
